import _ from 'underscore';
import axios from 'axios';
import pluralize from 'pluralize';
import { camelKeys, snakeKeys, isLegitObject, joinSentences } from '@/concerns/utilities';
import { ArgumentsType } from '@/types/common';
import { snakeCase } from 'change-case';

export type PathParams = (number | string)[];
export type Associations = Record<string, typeof BaseModel>; // eslint-disable-line @typescript-eslint/no-use-before-define, no-use-before-define

export function modelErrorsFrom(original: any, defaultMsg = "Something went wrong"): string[] {
  if (!original) return [defaultMsg];
  if (typeof original === 'string') return [original];

  if (Array.isArray(original)) {
    const errorLists = original.map(item => modelErrorsFrom(item, defaultMsg));
    return _.flatten(errorLists);
  }

  if (isLegitObject(original)) {
    return Object.keys(original).map((key) => {
      const errorSentences = modelErrorsFrom(original[key], defaultMsg);
      return `${key}: ${joinSentences(errorSentences)}`;
    });
  }

  const extractedErrors = _.flatten([
    original.errors
    || original.error
    || original?.data?.errors
    || original?.data?.error
    || original?.response?.data?.errors
    || original?.response?.data?.error
    || defaultMsg,
  ]);

  return _.flatten(extractedErrors.map(item => modelErrorsFrom(item, defaultMsg)));
}

export default class BaseModel {
  public id: null | number;

  constructor(options: Record<string, any> = {}) {
    this.id = typeof options.id === 'undefined' ? null : parseInt(options.id, 10);
  }

  static methodNotImplementedError(methodName: string): Error {
    return new Error(`Static method \`${methodName}()\` is not implemented in class \`${this.name}\``);
  }

  static parse<T extends typeof BaseModel>(this: T, data: any): InstanceType<T> {
    const camelized = camelKeys(data);
    const listAssociations = this.hasMany();
    const itemAssociations = this.hasOne();
    const expanded = Object.keys(camelized).reduce((memo, key) => {
      const value = camelized[key];
      const listClass = listAssociations[key];
      const itemClass = itemAssociations[key];

      let parsed;
      if (listClass) parsed = Array.isArray(value) ? value.map(item => listClass.parse(item)) : [];
      else if (itemClass) parsed = value && itemClass.parse(value);
      else parsed = value;

      return { ...memo, [key]: parsed };
    }, {});

    return new this(expanded) as InstanceType<T>;
  }

  static serialize<T extends typeof BaseModel, I extends InstanceType<T>>(this: T, item: Partial<I>): Record<string, any> {
    const data = item.toJson ? item.toJson() : JSON.parse(JSON.stringify(item));
    const snakedData = snakeKeys(data);
    const itemParam = this.itemParam();
    return itemParam ? { [itemParam]: snakedData } : snakedData;
  }

  // Optionally override this in the inheritor
  static hasMany(): Associations {
    return {};
  }

  // Optionally override this in the inheritor
  static hasOne(): Associations {
    return {};
  }

  // Must override this in the inheritor
  static itemName(): string {
    // return camelCase(this.name); // doesn't work because of uglifier
    throw this.methodNotImplementedError('itemName');
  }

  static collectionName(): string {
    return pluralize(this.itemName());
  }

  static itemParam(): string | null {
    return snakeCase(this.itemName());
  }

  static collectionParam(): string | null {
    return snakeCase(this.collectionName());
  }

  // Optionally override this in the inheritor
  static indexUrl(..._params: PathParams): string {
    return `/${this.collectionParam()}.json`;
  }

  // Optionally override this in the inheritor
  static showUrl(...params: PathParams): string {
    const id = params[0];
    return `/${this.collectionParam()}/${id}.json`;
  }

  static createUrl(...params: PathParams): string {
    return this.indexUrl(...params);
  }

  static updateUrl(...params: PathParams): string {
    return this.showUrl(...params);
  }

  static destroyUrl(...params: PathParams): string {
    return this.showUrl(...params);
  }

  static index<T extends typeof BaseModel>(this: T, ...indexUrlParams: ArgumentsType<T['indexUrl']>): Promise<InstanceType<T>[]> {
    const url = this.indexUrl(...indexUrlParams);
    return axios.get(url).then(({ data }) => data.map((item: any) => this.parse(item)));
  }

  static show<T extends typeof BaseModel>(this: T,  ...showUrlParams: ArgumentsType<T['showUrl']>): Promise<InstanceType<T>> {
    const url = this.showUrl(...showUrlParams);
    return axios.get(url).then(({ data }) => this.parse(data));
  }

  static create<T extends typeof BaseModel, I extends InstanceType<T>>(this: T, item: Partial<I>, ...createUrlParams: ArgumentsType<T['createUrl']>): Promise<InstanceType<T>> {
    const url = this.createUrl(...createUrlParams);
    const data = this.serialize(item);
    return axios.post(url, data).then(({ data }) => this.parse(data));
  }

  static update<T extends typeof BaseModel, I extends InstanceType<T>>(this: T, item: Partial<I>, ...updateUrlParams: ArgumentsType<T['updateUrl']>): Promise<InstanceType<T>> {
    const url = this.updateUrl(...updateUrlParams);
    const data = this.serialize(item);
    return axios.patch(url, data).then(({ data }) => this.parse(data));
  }

  static destroy<T extends typeof BaseModel>(this: T, ...updateUrlParams: ArgumentsType<T['updateUrl']>): Promise<InstanceType<T>> {
    const url = this.destroyUrl(...updateUrlParams);
    return axios.delete(url).then(({ data }) => this.parse(data));
  }

  get isPersisted(): boolean {
    return !!this.id;
  }

  get isNew(): boolean {
    return !this.isPersisted;
  }

  modelClass<T extends typeof BaseModel>(this: InstanceType<T>): T {
    return this.constructor as T;
  }

  // Optionally override this in the inheritor
  indexUrlParams(): PathParams {
    return [];
  }

  // Optionally override this in the inheritor
  showUrlParams(): PathParams {
    return [this.id || 0];
  }

  createUrlParams(): PathParams {
    return this.indexUrlParams();
  }

  updateUrlParams(): PathParams {
    return this.showUrlParams();
  }

  destroyUrlParams(): PathParams {
    return this.showUrlParams();
  }

  indexUrl(): string {
    return this.modelClass().indexUrl(...this.indexUrlParams());
  }

  showUrl(): string {
    return this.modelClass().showUrl(...this.showUrlParams());
  }

  createUrl(): string {
    return this.modelClass().createUrl(...this.createUrlParams());
  }

  updateUrl(): string {
    return this.modelClass().updateUrl(...this.updateUrlParams());
  }

  destroyUrl(): string {
    return this.modelClass().destroyUrl(...this.destroyUrlParams());
  }

  serialize<T extends BaseModel>(this: T): Record<string, any> {
    return this.modelClass().serialize(this);
  }

  // Optionally override this in the inheritor
  toJson(): Record<string, any> {
    return JSON.parse(JSON.stringify(this));
  }

  assignAttrs<T extends BaseModel>(this: T, attrs: Partial<T>): T {
    Object.assign(this, attrs);
    return this;
  }

  // Only updates the given attributes. Only sets updated values on this
  // instance from the response if they were in the attributes given. If the
  // request fails, the attributes will not change on this instance.
  updateAttrs<T extends BaseModel>(this: T, attrs: Partial<T>): Promise<T> {
    const request = this.modelClass().update(attrs, ...this.updateUrlParams()) as Promise<T>;
    return request.then((instance) => {
      // const whitelistedAttrs = Object.keys(attrs) as (keyof T)[];
      const whitelistedAttrs = Object.keys(attrs);
      return this.assignAttrs(_.pick(instance, ...whitelistedAttrs) as Partial<T>);
    });
  }

  save<T extends BaseModel>(this: T): Promise<T> {
    const request = this.id
      ? this.modelClass().update(this, ...this.updateUrlParams())
      : this.modelClass().create(this, ...this.createUrlParams());

    return request.then(instance => this.assignAttrs(instance as T));
  }

  reload<T extends BaseModel>(this: T): Promise<T> {
    if (!this.id) return Promise.resolve(this);

    const request = this.modelClass().show(...this.showUrlParams());
    return request.then(instance => this.assignAttrs(instance as T));
  }

  destroy<T extends BaseModel>(this: T): Promise<T> {
    if (!this.id) return Promise.resolve(this);

    const request = this.modelClass().destroy(...this.destroyUrlParams());
    return request.then(instance => this.assignAttrs(instance as T));
  }
}
