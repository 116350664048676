import BaseModel, { PathParams } from '@/models/BaseModel';
import { toInt } from '@/concerns/utilities';

export default class PostTag extends BaseModel {
  public id: null | number;
  public userId: null | number;
  public postId: null | number;
  public tagId: null | number;
  public karma: number;
  public createdAt: null | Date;
  public updatedAt: null | Date;
  public tagName: string;

  constructor(options: Partial<PostTag> = {}) {
    super(options);
    this.id = toInt(options.id) || null;
    this.userId = toInt(options.userId) || null;
    this.postId = toInt(options.postId) || null;
    this.tagId = toInt(options.tagId) || null;
    this.karma = toInt(options.karma) || 0;
    this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
    this.tagName = options.tagName || "";
  }

  static itemName(): string {
    return 'postTag';
  }

  static indexUrl(postId: number): string {
    return `/posts/${postId}/post_tags.json`;
  }

  indexUrlParams(): PathParams {
    return [this.postId || 0];
  }

  toJson(): Record<string, any> {
    return JSON.parse(JSON.stringify({
      ...this,
      tagAttributes: { // TODO: add option in `hasMany()` associations
        name: this.tagName,
      },
    }));
  }
}
