












































import Vue, { PropType } from 'vue';
import Palette from '@/components/paint/Palette.vue';
import Toolbox from '@/components/paint/Toolbox.vue';
import PainterTool, { allTools } from '@/models/PainterTool';
import PainterCanvas from '@/concerns/painter/PainterCanvas';

let painterCanvas: PainterCanvas;

export default Vue.extend({
  components: {
    Palette,
    Toolbox,
  },

  props: {
    size: {
      type: Number,
      default: 300,
    },

    tools: {
      type: Array as PropType<PainterTool[]>,
      default: (): PainterTool[] => allTools(),
    },

    scale: {
      type: Number,
      default: 1,
    },

    src: {
      type: String,
      default: '',
    },

    transparency: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const firstTool = this.tools.find(({ name }) => name !== 'eraser') || this.tools[0];
    return {
      currentTool: firstTool,
      previousTool: firstTool,
      currentColor: '#000000',
      currentText: '',
    };
  },

  watch: {
    currentTool(newVal: PainterTool, oldVal: PainterTool): void {
      this.previousTool = oldVal;
      painterCanvas.setTool(newVal);
      if (oldVal.name === 'text' && newVal.name !== 'text') this.finishTextInput();
      switch (newVal.name) {
        case 'mirror-x':
          painterCanvas.mirrorX();
          this.$nextTick(() => { this.currentTool = this.previousTool });
          break;
        case 'mirror-y':
          painterCanvas.mirrorY();
          this.$nextTick(() => { this.currentTool = this.previousTool });
          break;
        case 'rotate-clockwise':
          painterCanvas.rotate(1);
          this.$nextTick(() => { this.currentTool = this.previousTool });
          break;
        case 'rotate-counterclockwise':
          painterCanvas.rotate(-1);
          this.$nextTick(() => { this.currentTool = this.previousTool });
          break;
        default: break;
      }
    },

    currentColor(newVal: string, _oldVal: string): void {
      painterCanvas.setColor(newVal);
    },

    currentText(newVal: string, _oldVal: string): void {
      painterCanvas.text = newVal;
    },

    src(newVal: string, oldVal: string): void {
      if (newVal === oldVal) return;

      painterCanvas.loadImageUrl(newVal);
    },
  },

  mounted(): void {
    const canvas = this.$refs.painterCanvas as HTMLCanvasElement;
    const size = { width: this.size, height: this.size };
    painterCanvas = new PainterCanvas(
      canvas,
      size,
      this.currentTool,
      this.currentColor,
      this.scale,
      this.src,
      this.transparency ? 'rgba(0, 0, 0, 0)' : '#FFFFFF',
    );
    painterCanvas.onTextChange((newVal, _oldVal) => {
      if (newVal !== this.currentText) this.currentText = newVal;
    });
    painterCanvas.onReadyForText(() => {
      (this.$refs.hiddenTextInput as HTMLInputElement).focus();
    });
    painterCanvas.start();
  },

  methods: {
    finishTextInput(): void {
      painterCanvas.finishTextInput();
      this.currentText = '';
    },

    base64Encode(): string {
      const canvas = this.$refs.painterCanvas as HTMLCanvasElement;
      return canvas.toDataURL();
    },
  },
});
