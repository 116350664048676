import Vue, { VNode } from 'vue';
import { BvToastOptions } from "bootstrap-vue";
import { modelErrorsFrom } from '@/models/BaseModel';
import { joinSentences } from '@/concerns/utilities';

export function toastError(this: Vue, reason: any, toastOptions: Partial<BvToastOptions> = {}): void {
  const errors = modelErrorsFrom(reason);
  this.$bvToast.toast(joinSentences(errors), {
    title: "aww sheeit",
    variant: 'danger',
    autoHideDelay: 5000,
    appendToast: true,
    ...toastOptions,
  });
}

export function toastSuccess(this: Vue, message: string | VNode | VNode[], toastOptions: Partial<BvToastOptions> = {}): void {
  this.$bvToast.toast(message, {
    title: "aww yeee",
    variant: 'success',
    autoHideDelay: 5000,
    appendToast: true,
    ...toastOptions,
  });
}
