import BaseModel, { Associations, PathParams } from '@/models/BaseModel';
import { toInt } from '@/concerns/utilities';

export default class Comment extends BaseModel {
  public id: null | number;
  public userId: null | number;
  public postId: null | number;
  public parentCommentId: null | number;
  public username: string;
  public originalUsername: string;
  public body: string;
  public karma: number;
  public editedAt: null | Date;
  public removedAt: null | Date;
  public createdAt: null | Date;
  public updatedAt: null | Date;
  public comments: Comment[];
  private _commentCount: number;

  constructor(options: Partial<Comment> = {}) {
    super(options);
    this.id = toInt(options.id) || null;
    this.userId = toInt(options.userId) || null;
    this.postId = toInt(options.postId) || null;
    this.parentCommentId = toInt(options.parentCommentId) || null;
    this.username = options.username || "";
    this.originalUsername = options.originalUsername || "";
    this.body = options.body || "";
    this.karma = toInt(options.karma) || 0;
    this.editedAt = options.editedAt ? new Date(options.editedAt) : null;
    this.removedAt = options.removedAt ? new Date(options.removedAt) : null;
    this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
    this.comments = options.comments || [];
    this._commentCount = options.commentCount || this.comments.length;
  }

  static itemName(): string {
    return 'comment';
  }

  static hasMany(): Associations {
    return {
      comments: Comment,
    };
  }

  static indexUrl(postId: number): string {
    return `/posts/${postId}/comments.json`;
  }

  // A settable cache for the comment count
  get commentCount(): number {
    this._commentCount = this.comments.length || this._commentCount;
    return this._commentCount;
  }

  set commentCount(count: number) {
    this._commentCount = count;
  }

  indexUrlParams(): PathParams {
    return [this.postId || 0];
  }
}
