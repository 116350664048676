import _ from 'underscore';
import Vue from 'vue';
import Vuex from 'vuex';
import Post from '@/models/Post';
import User from '@/models/User';
import RoomTileTemplate from '@/models/RoomTileTemplate';
import RoomItem from '@/models/RoomItem';
import Countdown, { MinimalCountdownData } from '@/models/Countdown';

Vue.use(Vuex);

export default new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production',

  state: {
    user: new User(),
    userFetched: false,
    posts: [] as Post[],
    roomTileTemplates: [] as RoomTileTemplate[],
    roomItems: [] as RoomItem[],
    countdownsMinimal: [] as MinimalCountdownData[],
    countdowns: [] as Countdown[],
  },

  mutations: {
    setPosts(state, posts: Post[]): void {
      state.posts = posts;
    },

    setUserFetched(state, userFetched: boolean): void {
      state.userFetched = userFetched;
    },

    setRoomTileTemplates(state, roomTileTemplates: RoomTileTemplate[]): void {
      state.roomTileTemplates = roomTileTemplates;
    },

    addRoomTileTemplate(state, template: RoomTileTemplate): void {
      state.roomTileTemplates = _.uniq([template, ...state.roomTileTemplates], 'id');
    },

    setRoomItems(state, roomItems: RoomItem[]): void {
      state.roomItems = roomItems;
    },

    setPost(state, post: Post): void {
      const postIndex = state.posts.findIndex(({ id }) => id === post.id);
      if (postIndex === -1) {
        state.posts = _.sortBy([...state.posts, post], 'createdAt').reverse();
      } else {
        state.posts.splice(postIndex, 1, post);
      }
    },

    setUser(state, user: User): void {
      state.user = user;
    },

    setCountdownsMinimal(state, countdownsMinimal: MinimalCountdownData[]): void {
      state.countdownsMinimal = countdownsMinimal;
    },

    setCountdowns(state, countdowns: Countdown[]): void {
      state.countdowns = countdowns;
    },
  },

  actions: {
    fetchPosts(context): Promise<void> {
      return Post.index().then((posts) => {
        context.commit('setPosts', posts);
      });
    },

    fetchPost(context, id: number): Promise<void> {
      return Post.show(id).then((post) => {
        context.commit('setPost', post);
      });
    },

    fetchCurrentUser(context): Promise<void> {
      return User.show('current').then((user) => {
        context.commit('setUser', user);
        context.commit('setUserFetched', true);
      });
    },

    updateUser(context, attrs: Partial<User>): Promise<void> {
      const { id } = context.state.user;
      if (!id) throw new Error('Not logged in');

      return User.update(attrs, id).then((user) => {
        context.commit('setUser', user);
      });
    },

    fetchCountdownsMinimal(context): Promise<void> {
      return Countdown.indexMinimal().then((countdownsMinimal) => {
        context.commit('setCountdownsMinimal', countdownsMinimal);
      });
    },

    fetchCountdowns(context): Promise<void> {
      return Countdown.index().then((countdowns) => {
        context.commit('setCountdowns', countdowns);
      });
    },
  },

  getters: {
    signedIn(state): boolean {
      return !!state.user.id;
    },

    roomTileTemplatesById(state): Record<number, RoomTileTemplate> {
      return state.roomTileTemplates.reduce((memo, template) => ({
        ...memo,
        [template?.id || 'null']: template,
      }), {});
    },

    roomItemsById(state): Record<number, RoomItem> {
      return state.roomItems.reduce((memo, item) => ({
        ...memo,
        [item?.id || 'null']: item,
      }), {});
    },
  },
});
