export type DrawFrame = (ctx: CanvasRenderingContext2D, loopCount: number) => void;

const insertedFrames = [] as DrawFrame[];

const clearCanvasContext = (ctx: CanvasRenderingContext2D): void => {
  const { width, height } = ctx.canvas;
  ctx.clearRect(0, 0, width, height);
};

export const clearFrame = (): void => {
  insertedFrames.push(clearCanvasContext);
};

const drawInsertedFrames = (ctx: CanvasRenderingContext2D, loopCount: number): void => {
  for (let i = 0; i < insertedFrames.length; i++) {
    const insertedDraw = insertedFrames.shift();
    if (insertedDraw) insertedDraw(ctx, loopCount);
  }
};

export default function frameLoop(ctx: CanvasRenderingContext2D, draw: DrawFrame, clearBeforeFrame = false, loopCount = 0): void {
  requestAnimationFrame(() => frameLoop(ctx, draw, clearBeforeFrame, loopCount + 1));
  if (clearBeforeFrame) clearCanvasContext(ctx);
  drawInsertedFrames(ctx, loopCount);
  draw(ctx, loopCount);
}
