import axios from 'axios';

export default function initAxios(): void {
  const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
  const token = csrfTokenMeta?.getAttribute('content');
  if (!token) throw new Error("No CSRF token found in meta tag content");

  axios.defaults.headers.post['X-CSRF-Token'] = token;
  axios.defaults.headers.put['X-CSRF-Token'] = token;
  axios.defaults.headers.patch['X-CSRF-Token'] = token;
  axios.defaults.headers.delete['X-CSRF-Token'] = token;
}
