import { roundToNearestHalf } from '@/concerns/utilities';
import { Coords3d } from '@/models/Room';

export interface Coords2d {
  x: number;
  y: number;
}

export interface Vector2d {
  dx: number;
  dy: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface GridPosition {
  row: number;
  col: number;
}

// NOTE: Only works for square canvases. In the future, if you want to use it on
//       rectangular canvases, this should be rewritten to explicitly supply the
//       canvas width, and then you'd calculate the `imageDataWidth` by
//       multiplying the canvas width by the `window.devicePixelRatio` and then
//       flooring (I think? Maybe round/ceil, but it seems like a floor since
//       for a `devicePixelRatio` of 2.625, `2.625 * 300 = 787.5`, whereas the
//       actual `imageDataWidth` is *empirically* 787) that value.
//
// Turns { x: #, y: # } into the starting point of a [R, G, B, A] slice of the
// Uint8ClampedArray returned by ctx.getImageData(...).data (R, G, B, and A are
// values between 0 and 256)
export function coordsToImageDataIndex(imageData: Uint8ClampedArray, coords: Coords2d, scale: number): number {
  const { x, y } = coords;
  const imageDataWidth = Math.sqrt(imageData.length / 4);
  const imageDataX = Math.round(scale * x);
  const imageDataY = Math.round(scale * y);
  return 4 * (imageDataX + (imageDataY * imageDataWidth));
}

// Inverse of the above
export function imageDataIndexToCoords(imageData: Uint8ClampedArray, index: number, scale: number): Coords2d {
  const imageDataSubsetIndex = index / 4;
  const imageDataWidth = Math.sqrt(imageData.length / 4);
  const imageDataX = imageDataSubsetIndex % imageDataWidth;
  const imageDataY = (imageDataSubsetIndex / imageDataWidth) - imageDataX;
  const x = roundToNearestHalf(imageDataX / scale);
  const y = roundToNearestHalf(imageDataY / scale);
  return { x, y };
}

// Turns { x: #, y: # } into a [R, G, B, A] slice of the Uint8ClampedArray
// returned by ctx.getImageData(...).data (R, G, B, and A are values between 0
// and 256)
export function pointDataFrom(imageData: Uint8ClampedArray, coords: Coords2d, scale: number): Uint8ClampedArray {
  const pointIndex = coordsToImageDataIndex(imageData, coords, scale);
  return imageData.slice(pointIndex, pointIndex + 4);
}

// Compares two [R, G, B, A] slices (from two points) to see if they're close
// enough to be considered "the same".
export function pointDataEqual(pointData1: Uint8ClampedArray, pointData2: Uint8ClampedArray): boolean {
  return pointData1[0] === pointData2[0]
    && pointData1[1] === pointData2[1]
    && pointData1[2] === pointData2[2]
    && (
      pointData1[3] === pointData2[3]
      || Math.abs(pointData1[3] - pointData2[3]) < 30
    );
}

export function pointEqual(point1: Coords2d, point2: Coords2d): boolean {
  return point1.x === point2.x && point1.y === point2.y;
}

// Get the point immediately north/south/west/east of the origin point.
export const northOf = (origin: Coords2d): Coords2d => ({ x: origin.x, y: origin.y - 0.5 });
export const eastOf = (origin: Coords2d): Coords2d => ({ x: origin.x + 0.5, y: origin.y });
export const southOf = (origin: Coords2d): Coords2d => ({ x: origin.x, y: origin.y + 0.5 });
export const westOf = (origin: Coords2d): Coords2d => ({ x: origin.x - 0.5, y: origin.y });

// Go straight up from `origin` and get the last point that's equal in color to
// that of the origin point.
export function northernmostEqualPoint(imageData: Uint8ClampedArray, origin: Coords2d, comparisonData: Uint8ClampedArray, scale: number): Coords2d {
  let furthestNorthPoint: Coords2d | null = null;

  // for (let i = 0; origin.y - i >= 0; i++) {
  for (let i = 0; origin.y - i >= 0; i += 0.5) {
    const northwardPoint = { x: origin.x, y: origin.y - i };
    const northwardPointData = pointDataFrom(imageData, northwardPoint, scale);
    if (!pointDataEqual(comparisonData, northwardPointData)) break;
    furthestNorthPoint = northwardPoint;
  }

  return furthestNorthPoint || origin;
}

// Go straight down from `origin` and get the last point that's equal in color
// to that of the origin point.
export function southernmostEqualPoint(imageData: Uint8ClampedArray, origin: Coords2d, comparisonData: Uint8ClampedArray, size: Size, scale: number): Coords2d {
  let southernmostPoint: Coords2d | null = null;

  // for (let i = 0; origin.y + i < size.height; i++) {
  for (let i = 0; origin.y + i < size.height; i += 0.5) {
    const southwardPoint = { x: origin.x, y: origin.y + i };
    const southwardPointData = pointDataFrom(imageData, southwardPoint, scale);
    if (!pointDataEqual(comparisonData, southwardPointData)) break;
    southernmostPoint = southwardPoint;
  }

  return southernmostPoint || origin;
}

// Go straight left from `origin` and get the last point that's equal in color
// to that of the origin point.
export function westernmostEqualPoint(imageData: Uint8ClampedArray, origin: Coords2d, comparisonData: Uint8ClampedArray, scale: number): Coords2d {
  let westernmostPoint: Coords2d | null = null;

  for (let i = 0; origin.x - i >= 0; i += 0.5) {
    const westwardPoint = { x: origin.x - i, y: origin.y };
    const westwardPointData = pointDataFrom(imageData, westwardPoint, scale);
    if (!pointDataEqual(comparisonData, westwardPointData)) break;
    westernmostPoint = westwardPoint;
  }

  return westernmostPoint || origin;
}

// Go straight right from `origin` and get the last point that's equal in color
// to that of the origin point.
export function easternmostEqualPoint(imageData: Uint8ClampedArray, origin: Coords2d, comparisonData: Uint8ClampedArray, size: Size, scale: number): Coords2d {
  let easternmostPoint: Coords2d | null = null;

  for (let i = 0; origin.x + i < size.width; i += 0.5) {
    const eastwardPoint = { x: origin.x + i, y: origin.y };
    const eastwardPointData = pointDataFrom(imageData, eastwardPoint, scale);
    if (!pointDataEqual(comparisonData, eastwardPointData)) break;
    easternmostPoint = eastwardPoint;
  }

  return easternmostPoint || origin;
}

// Go straight left and right from `origin` and get the whole row of points that
// are equal in color to that of the origin point.
export function rowOfEqualPoints(imageData: Uint8ClampedArray, origin: Coords2d, comparisonData: Uint8ClampedArray, size: Size, scale: number): Coords2d[] {
  const westPoint = westernmostEqualPoint(imageData, origin, comparisonData, scale);
  const eastPoint = easternmostEqualPoint(imageData, origin, comparisonData, size, scale);
  const startX = westPoint.x;
  const endX = eastPoint.x;
  const { y } = origin;
  const row = [] as Coords2d[];
  for (let x = startX; x <= endX; x++) row.push({ x, y });
  return row;
}

// Whether or not the point exists within the size specified.
export function pointExists(point: Coords2d, size: Size): boolean {
  return point.x > 0 && point.x < size.width && point.y > 0 && point.y < size.height;
}

export function pointsBetween(origin: Coords2d, destination: Coords2d): Coords2d[] {
  const vector = {
    x: destination.x - origin.x,
    y: destination.y - origin.y,
  };

  const roundedCoords = ({ x, y }: Coords2d): Coords2d => ({
    x: roundToNearestHalf(x),
    y: roundToNearestHalf(y),
  });

  const roundedVector = roundedCoords(vector);
  if (roundedVector.x === 0 && roundedVector.y === 0) return [roundedCoords(origin)];

  const vectorAbsX = Math.abs(vector.x);
  const vectorAbsY = Math.abs(vector.y);
  const scale = 0.5 / Math.max(vectorAbsX, vectorAbsY); // to get it to be max 0.5px per point distance
  const dx = scale * vector.x;
  const dy = scale * vector.y;
  let { x, y } = origin;
  const points = [] as Coords2d[];

  while (Math.abs(x - origin.x) <= vectorAbsX && Math.abs(y - origin.y) <= vectorAbsY) {
    points.push(roundedCoords({ x, y }));
    x += dx;
    y += dy;
  }

  return points;
}

export function pointWithinRect(point: Coords2d, [origin, size]: [Coords2d, Size]): boolean {
  const correctedSize = {
    width: Math.abs(size.width),
    height: Math.abs(size.height),
  };

  const correctedOrigin = {
    x: size.width < 0 ? origin.x - correctedSize.width : origin.x,
    y: size.height < 0 ? origin.y - correctedSize.height : origin.y,
  };

  return point.x >= correctedOrigin.x
    && point.x <= correctedOrigin.x + correctedSize.width
    && point.y >= correctedOrigin.y
    && point.y <= correctedOrigin.y + correctedSize.height;
}

export function gridPositionsEqual(p1: GridPosition, p2: GridPosition): boolean {
  return p1.row === p2.row && p1.col === p2.col;
}

export function coords2dEqual(c1: Coords2d, c2: Coords2d): boolean {
  return c1.x === c2.x && c1.y === c2.y;
}

export function coords3dEqual(c1: Coords3d, c2: Coords3d): boolean {
  return c1.x === c2.x && c1.y === c2.y && c1.z === c2.z;
}

export function vectorBetween(c1: Coords2d, c2: Coords2d): Vector2d {
  return {
    dx: c2.x - c1.x,
    dy: c2.y - c1.y,
  };
}

export function sumVectors(...vectors: Vector2d[]): Vector2d {
  return vectors.reduce((sum, vector) => ({
    dx: sum.dx + vector.dx,
    dy: sum.dy + vector.dy,
  }), { dx: 0, dy: 0 });
}
