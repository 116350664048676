




















































import Vue from 'vue';
import store from '@/store';

export default Vue.extend({
  name: 'NavBar',

  props: {
    shrink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchString: "",
    };
  },

  computed: {
    username(): string {
      return store.state.user.username;
    },

    signedIn(): boolean {
      return store.getters.signedIn;
    },

    status(): string {
      return store.state.user.status;
    },
  },

  methods: {
    onSearch(_event: Event): void {
      // this.$emit('search', this.searchString);
      this.$emit('not-implemented');
    },

    setUserStatus(status: string): void {
      store.dispatch('updateUser', { status });
    },
  },
});
