import BaseModel, { Associations } from '@/models/BaseModel';
import PostUpvote from '@/models/PostUpvote';
import { toInt } from '@/concerns/utilities';

export default class User extends BaseModel {
  public id: null | number;
  public username: string;
  public name: string;
  public email: string;
  public admin: boolean;
  public moderator: boolean;
  public high: boolean;
  public status: string;
  public karma: number;
  public postKarma: number;
  public commentKarma: number;
  public tagKarma: number;
  public postTagKarma: number;
  public upvotedPostIds: number[];
  public postUpvotes: PostUpvote[];
  public createdAt: null | Date;
  public updatedAt: null | Date;

  constructor(options: Partial<User> = {}) {
    super(options);
    this.id = toInt(options.id) || null;
    this.username = options.username || '';
    this.name = options.name || '';
    this.email = options.email || '';
    this.admin = options.admin ?? false;
    this.moderator = options.moderator ?? false;
    this.high = options.high ?? false;
    this.status = options.status || (this.high ? 'high' : 'square');
    this.karma = toInt(options.karma) || 0;
    this.postKarma = toInt(options.postKarma) || 0;
    this.commentKarma = toInt(options.commentKarma) || 0;
    this.tagKarma = toInt(options.tagKarma) || 0;
    this.postTagKarma = toInt(options.postTagKarma) || 0;
    this.upvotedPostIds = options.upvotedPostIds || [];
    this.postUpvotes = options.postUpvotes || [];
    this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
  }

  static itemName(): string {
    return 'user';
  }

  static hasMany(): Associations {
    return {
      postUpvotes: PostUpvote,
    };
  }
}
