import BaseModel, { PathParams } from '@/models/BaseModel';
import { toInt } from '@/concerns/utilities';

export default class PostUpvote extends BaseModel {
  public id: null | number;
  public userId: null | number;
  public postId: null | number;
  public createdAt: null | Date;
  public updatedAt: null | Date;

  constructor(options: Partial<PostUpvote> = {}) {
    super(options);
    this.id = toInt(options.id) || null;
    this.userId = toInt(options.userId) || null;
    this.postId = toInt(options.postId) || null;
    this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
  }

  static itemName(): string {
    return 'postUpvote';
  }

  static indexUrl(postId: number): string {
    return `/posts/${postId}/post_upvotes.json`;
  }

  indexUrlParams(): PathParams {
    return [this.postId || 0];
  }
}
