export type PainterToolIcon = {
  bootstrap: string;
  style?: Partial<CSSStyleDeclaration>;
} | {
  src: string;
  style?: Partial<CSSStyleDeclaration>;
} | {
  text: string;
  style?: Partial<CSSStyleDeclaration>;
} | {
  style: Partial<CSSStyleDeclaration>;
};

export interface PainterToolVariant {
  name: string;
  icon: PainterToolIcon;
}

interface PainterTool {
  name: string;
  icon: PainterToolIcon;
  variantName: string;
  variants: PainterToolVariant[];
}

export default PainterTool;

export function allTools(): PainterTool[] {
  return [
    {
      name: 'eraser',
      icon: { bootstrap: 'file-minus' },
      variantName: '8px',
      variants: [
        { name: '1px', icon: { style: { width: '1px', height: '1px', backgroundColor: '#000000' } } },
        { name: '2px', icon: { style: { width: '2px', height: '2px', backgroundColor: '#000000' } } },
        { name: '4px', icon: { style: { width: '4px', height: '4px', backgroundColor: '#000000' } } },
        { name: '8px', icon: { style: { width: '8px', height: '8px', backgroundColor: '#000000' } } },
        { name: '16px', icon: { style: { width: '16px', height: '16px', backgroundColor: '#000000' } } },
        { name: '32px', icon: { style: { width: '32px', height: '32px', backgroundColor: '#000000' } } },
      ],
    },
    // {
    //   name: 'magnifier',
    //   icon: { bootstrap: 'search' },
    //   variantName: '1x',
    //   variants: [
    //     { name: '1x', icon: { text: '1x' } },
    //     { name: '2x', icon: { text: '2x' } },
    //     { name: '4x', icon: { text: '4x' } },
    //     { name: '8x', icon: { text: '8x' } },
    //   ],
    // },
    {
      name: 'pencil',
      icon: { bootstrap: 'pencil' },
      variantName: '',
      variants: [],
    },
    {
      name: 'brush',
      icon: { bootstrap: 'brush' },
      variantName: 'circle-4px',
      variants: [
        { name: 'circle-1px', icon: { style: { width: '1px', height: '1px', backgroundColor: '#000000' } } },
        { name: 'circle-4px', icon: { style: { width: '4px', height: '4px', borderRadius: '50%', backgroundColor: '#000000' } } },
        { name: 'circle-7px', icon: { style: { width: '7px', height: '7px', borderRadius: '50%', backgroundColor: '#000000' } } },
        { name: 'square-2px', icon: { style: { width: '2px', height: '2px', backgroundColor: '#000000' } } },
        { name: 'square-5px', icon: { style: { width: '5px', height: '5px', backgroundColor: '#000000' } } },
        { name: 'square-8px', icon: { style: { width: '8px', height: '8px', backgroundColor: '#000000' } } },
        // { name: 'diagonal-right-1px', icon: { /* ... */ } },
        // { name: 'diagonal-right-5px', icon: { /* ... */ } },
        // { name: 'diagonal-right-8px', icon: { /* ... */ } },
        // { name: 'diagonal-left-1px', icon: { /* ... */ } },
        // { name: 'diagonal-left-5px', icon: { /* ... */ } },
        // { name: 'diagonal-left-8px', icon: { /* ... */ } },
      ],
    },
    {
      name: 'fill',
      // icon: { bootstrap: 'droplet-fill' },
      icon: { bootstrap: 'bucket-fill' },
      variantName: '',
      variants: [],
    },
    // {
    //   name: 'spraypaint',
    //   icon: { /* ... */ },
    //   variantName: '...',
    //   variants: [/* ... */],
    // },
    {
      name: 'text',
      icon: { bootstrap: 'textarea-t' },
      variantName: 'background-transparent',
      variants: [
        { name: 'background-transparent', icon: { bootstrap: 'intersect' } },
        { name: 'background-solid', icon: { bootstrap: 'subtract' } },
      ],
    },
    {
      name: 'line',
      icon: { bootstrap: 'slash' },
      variantName: '1px',
      variants: [
        { name: '1px', icon: { style: { width: '24px', height: '1px', backgroundColor: '#000000' } } },
        { name: '2px', icon: { style: { width: '24px', height: '2px', backgroundColor: '#000000' } } },
        { name: '4px', icon: { style: { width: '24px', height: '4px', backgroundColor: '#000000' } } },
        { name: '8px', icon: { style: { width: '24px', height: '8px', backgroundColor: '#000000' } } },
      ],
    },
    // {
    //   name: 'curve',
    //   icon: { bootstrap: 'slash' },
    //   variantName: '1px',
    //   variants: [
    //     { name: '1px', icon: { style: { width: '24px', height: '1px', backgroundColor: '#000000' } } },
    //     { name: '2px', icon: { style: { width: '24px', height: '2px', backgroundColor: '#000000' } } },
    //     { name: '4px', icon: { style: { width: '24px', height: '4px', backgroundColor: '#000000' } } },
    //     { name: '8px', icon: { style: { width: '24px', height: '8px', backgroundColor: '#000000' } } },
    //   ],
    // },
    {
      name: 'rectangle',
      icon: { bootstrap: 'square' },
      variantName: 'hollow',
      variants: [
        { name: 'hollow', icon: { bootstrap: 'square' } },
        { name: 'fill', icon: { bootstrap: 'square-fill' } },
      ],
    },
    // {
    //   name: 'polygon',
    //   icon: { bootstrap: 'square' },
    //   variantName: 'hollow',
    //   variants: [
    //     { name: 'hollow', icon: { bootstrap: 'square' } },
    //     { name: 'fill', icon: { bootstrap: 'square-fill' } },
    //   ],
    // },
    {
      name: 'ellipse',
      icon: { bootstrap: 'circle' },
      variantName: 'hollow',
      variants: [
        { name: 'hollow', icon: { bootstrap: 'circle' } },
        { name: 'fill', icon: { bootstrap: 'circle-fill' } },
      ],
    },
    {
      name: 'mirror-x',
      icon: { bootstrap: 'arrow-left-right' },
      variantName: '',
      variants: [],
    },
    {
      name: 'mirror-y',
      icon: { bootstrap: 'arrow-down-up' },
      variantName: '',
      variants: [],
    },
    {
      name: 'rotate-clockwise',
      icon: { bootstrap: 'arrow-clockwise' },
      variantName: '',
      variants: [],
    },
    {
      name: 'rotate-counterclockwise',
      icon: { bootstrap: 'arrow-counterclockwise' },
      variantName: '',
      variants: [],
    },
    // {
    //   name: 'eyedropper',
    //   icon: { bootstrap: 'eye' },
    //   variantName: '',
    //   variants: [],
    // },
    // {
    //   name: 'roundedrect',
    //   icon: { bootstrap: 'circle' },
    //   variantName: 'hollow',
    //   variants: [
    //     { name: 'hollow', icon: { bootstrap: 'circle' } },
    //     { name: 'fill', icon: { bootstrap: 'circle-fill' } },
    //   ],
    // },
  ];
}

export function getTool(toolName: string, toolVariant?: string): PainterTool {
  const tools = allTools();
  const tool = tools.find(({ name }) => name === toolName);
  if (!tool) throw new Error(`Cannot find tool '${toolName}'`);

  if (!toolVariant || tool.variantName === toolVariant) return tool;

  const variant = tool.variants.find(({ name }) => name === toolVariant);
  if (!variant) throw new Error(`Cannot find variant '${toolVariant}' for tool '${toolName}'`);

  return { ...tool, variantName: variant.name };
}

export function getTools(...toolNames: string[]): PainterTool[] {
  return allTools().filter(({ name }) => toolNames.includes(name));
}
