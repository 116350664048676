import _ from 'underscore';
import axios from 'axios';
import moment from 'moment';
import BaseModel from '@/models/BaseModel';
import { toInt } from '@/concerns/utilities';
import { base64EncodeFile } from '@/concerns/files';

export const DAY_STRING_FORMAT = 'YYYY-MM-DD';
export const TIME_STRING_FORMAT = 'HH:mm:ss';

export const TIMER_DISPLAY_OPTIONS = [
  'hh:mm:ss',
  'h:mm:ss',
  'hh:mm',
  'h:mm',
  'hh',
  'h',
  'human description',
  'seconds left',
  'minutes left',
  'hours left',
] as const;

export type TimerDisplayOption = (typeof TIMER_DISPLAY_OPTIONS)[number];

export type CountdownOptions = Partial<Countdown> & {
  countdownName?: string;
  targetDayString?: string;
  targetTimeString?: string;
  splashImageFile?: File | null;
}

export interface MinimalCountdownData {
  id: number;
  name: string;
}

export const startOfNextHalfHour = (): Date => {
  const thirtyMinutesFromNow = moment().add(30, 'minutes').startOf('minute');
  const minutesAfterHalfHour = thirtyMinutesFromNow.minutes() % 30;
  const thirtyMoment = thirtyMinutesFromNow.clone().subtract(minutesAfterHalfHour, 'minutes');
  return thirtyMoment.toDate();
};

export const TIMER_FONT_STYLES = ['bold', 'italic', 'underline'] as const;

export type TimerFontStyle = (typeof TIMER_FONT_STYLES)[number];

export default class Countdown extends BaseModel {
  public id: null | number;
  public userId: null | number;
  public username: string;
  public name: string;
  public targetDate: Date;
  public splashImage: string; // base64-encoded image for upload
  public splashImageUrl: string;
  public splashImageSize: number;
  public backdropColor: string;
  public timerX: number;
  public timerY: number;
  public timerColor: string;
  public timerFontSize: number;
  public timerFontFamily: string;
  public timerFontStyles: TimerFontStyle[];
  public timerPrependText: string;
  public timerAppendText: string;
  public timerDisplayOption: TimerDisplayOption;
  public createdAt: null | Date;
  public updatedAt: null | Date;

  constructor(options: CountdownOptions = {}) {
    super(options);
    this.id = toInt(options.id) || null;
    this.userId = toInt(options.userId) || null;
    this.username = options.username || '';
    this.name = options.name || options.countdownName || '';

    if (options.targetDate) {
      this.targetDate = options.targetDate;
    } else if (options.targetDayString && options.targetTimeString) {
      this.targetDate = moment(
        `${options.targetDayString} ${options.targetTimeString}`,
        `${DAY_STRING_FORMAT} ${TIME_STRING_FORMAT}`,
      ).toDate();
    } else {
      this.targetDate = startOfNextHalfHour();
    }

    if (options.splashImage) {
      this.splashImage = options.splashImage;
    } else if (options.splashImageFile) {
      // Ehhhh... async assignment in the constructor? Not ideal... luckily just
      // for backward-compatibility with the `localStorage` persistence strategy
      // though, so it'll be obsolete as soon as all countdowns are saved on the
      // back end.
      this.splashImage = ''; // (an immediate default to "" for synchronicity)
      base64EncodeFile(options.splashImageFile).then((encodedFile) => {
        this.splashImage = encodedFile;
      });
    } else {
      this.splashImage = '';
    }

    this.splashImageUrl = options.splashImageUrl
      || (options.splashImageFile && URL.createObjectURL(options.splashImageFile))
      || '';

    this.splashImageSize = options.splashImageSize || 100;
    this.backdropColor = options.backdropColor || '#000000';
    this.timerX = options.timerX || 0;
    this.timerY = options.timerY || 0;
    this.timerColor = options.timerColor || '#FFFFFF';
    this.timerFontSize = options.timerFontSize || 24;
    this.timerFontFamily = options.timerFontFamily || 'Lato';
    this.timerFontStyles = options.timerFontStyles || [];
    this.timerPrependText = options.timerPrependText || '';
    this.timerAppendText = options.timerAppendText || '';
    this.timerDisplayOption = options.timerDisplayOption || 'hh:mm:ss';
    this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
  }

  static itemName(): string {
    return 'countdown';
  }

  static indexMinimal(): Promise<MinimalCountdownData[]> {
    const url = `${this.indexUrl()}?minimal=true`;
    return axios.get(url).then(({ data }) => data);
  }

  toJson(): Record<string, any> {
    return _.pick(
      this,
      'name',
      'targetDate',
      'splashImage',
      'splashImageSize',
      'backdropColor',
      'timerX',
      'timerY',
      'timerColor',
      'timerFontSize',
      'timerFontFamily',
      'timerFontStyles',
      'timerPrependText',
      'timerAppendText',
      'timerDisplayOption',
    );
  }
}
