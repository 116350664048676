













import Vue from 'vue';

export default Vue.extend({
  props: {
    fullscreen: {
      type: Boolean,
      default: true,
    },
  },
});
