
















import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '#000000',
    },

    colors: {
      type: Array as PropType<string[]>,
      default: (): string[] => [
        '#000000',
        '#787878',
        '#780507',
        '#757915',
        '#0E7812',
        '#0F7777',
        '#0C0B76',
        '#7A0D76',
        '#76793C',
        '#033637',
        '#2E73FA',
        '#0C3376',
        '#4D24FA',
        '#773B0B',
        '#FFFFFF',
        '#BBBBBB',
        '#FC171B',
        '#FAFD39',
        '#29FD31',
        '#2CFEFE',
        '#3624FA',
        '#FC28FB',
        '#FBFD83',
        '#2AFD81',
        '#7CFEFE',
        '#8275FA',
        '#FC1878',
        '#FD7D41',
      ],
    },
  },
});
