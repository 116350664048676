























import _ from 'underscore';
import Vue from 'vue';
import NavBar from '@/components/NavBar.vue';
import LoadingSplash from '@/components/common/splashes/LoadingSplash.vue';

export default Vue.extend({
  name: 'App',

  components: {
    NavBar,
    LoadingSplash,
  },

  data() {
    return {
      scrolled: false,
    };
  },

  mounted(): void {
    const syncScrolled = _.throttle(() => {
      this.scrolled = window.scrollY > 100;
    }, 300);

    syncScrolled();
    window.addEventListener('scroll', syncScrolled);
  },

  methods: {
    showApologyModal(): void {
      this.$bvModal.msgBoxOk(
        "shit, sorry, I haven't implemented that, it's coming soon",
        {
          title: "ah, fuck, sorry",
          okTitle: "damn it, okay, shit I guess that's fine",
          headerClass: 'border-bottom-0',
          footerClass: 'border-top-0',
        },
      );
    },
  },
});
