import Mouse from '@/concerns/painter/Mouse';
import frameLoop from '@/concerns/painter/frameLoop';
import PainterTool from '@/models/PainterTool';
import { bucketFill } from '@/concerns/painter/bucketFill';
import { pointsBetween, Size, Coords2d } from '@/concerns/painter/cartography';
import { roundToNearestHalf } from '@/concerns/utilities';

type TextChangeHandler = (newVal: string, oldVal: string) => void;

export default class PainterCanvas {
  public mouse: Mouse;
  public canvas: HTMLCanvasElement;
  public size: Size;
  public ctx: CanvasRenderingContext2D;
  public tool: PainterTool;
  public color: string;
  public bgColor: string;
  public cursor: Coords2d;
  public lastCursor: Coords2d;
  public visualScale: number;
  private scale: number;
  private started = false;
  private drawing = false;
  private snapshot: ImageData;
  private textWidth: number = 0;
  private _tempRect: [Coords2d, Size] | null = null;
  private _text: string = '';
  private textChangeHandlers: TextChangeHandler[] = [];
  private readyForTextHandlers: (() => void)[] = [];

  constructor(canvas: HTMLCanvasElement, size: Size, tool: PainterTool, paintColor = '#000000', visualScale = 1, initialSrc = '', initialBgColor = 'rgba(255, 255, 255, 1)') {
    this.canvas = canvas;
    this.mouse = new Mouse(this.canvas);
    this.size = size;
    this.visualScale = visualScale;
    this.scale = window.devicePixelRatio * this.visualScale;
    const ctx = this.canvas.getContext('2d');
    if (!ctx) throw new Error("Canvas '2d' context is not supported.");

    this.ctx = ctx;
    this.tool = tool;
    this.color = paintColor;
    this.bgColor = initialBgColor;
    this.cursor = { x: 0, y: 0 };
    this.lastCursor = this.cursor;

    this.sizeCanvas(this.canvas, this.ctx);

    if (initialSrc) {
      this.loadImageUrl(initialSrc);
    } else {
      this.fillCanvas(initialBgColor);
    }

    this.snapshot = this.getSnapshot();
  }

  get width(): number { return this.size.width }
  get height(): number { return this.size.height }

  get tempRect(): [Coords2d, Size] | null { return this._tempRect }
  set tempRect(rect: [Coords2d, Size] | null) {
    if (!rect) {
      this._tempRect = rect;
    } else {
      const [coords, size] = rect;
      const absCoords = {
        x: size.width < 0 ? coords.x + size.width : coords.x,
        y: size.height < 0 ? coords.y + size.height : coords.y,
      };
      const absSize = {
        width: Math.abs(size.width),
        height: Math.abs(size.height),
      };
      this._tempRect = [absCoords, absSize];
    }
  }

  get text(): string { return this._text }
  set text(newText: string) {
    const oldText = this._text;
    this._text = newText;
    this.textChangeHandlers.forEach(handler => handler(newText, oldText));
  }

  onTextChange(handler: TextChangeHandler): void {
    this.textChangeHandlers.push(handler);
  }

  onReadyForText(handler: () => void): void {
    this.readyForTextHandlers.push(handler);
  }

  loadImageUrl(src: string): void {
    const image = new Image();
    image.src = src;
    image.addEventListener('load', () => {
      this.ctx.drawImage(image, 0, 0, this.size.width, this.size.height);
    });
  }

  fillCanvas(bgColor: string): void {
    this.ctx.save();
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.ctx.fillStyle = bgColor;
    this.ctx.fillRect(0, 0, this.width, this.height);
    this.ctx.restore();
  }

  start(): void {
    if (this.started) return;

    this.started = true;

    this.mouse.onPress(() => {
      this.updateCursor();
      this.startDrawing();
    });

    this.mouse.onDepress(() => {
      this.updateCursor();
      this.stopDrawing();
    });

    this.mouse.onMove(() => {
      this.updateCursor();
      if (this.drawing) this.draw();
    });

    frameLoop(this.ctx, () => {
      this.draw();
    });
  }

  updateCursor(): void {
    this.lastCursor = this.cursor;
    this.cursor = this.canvasCoordsFrom(this.mouse);
  }

  startDrawing(): void {
    if (this.drawing) return;

    this.drawing = true;
    if (this.tempRect) {
      this.finishTextInput();
    }
    this.snapshot = this.getSnapshot();
    this.ctx.beginPath();
    this.setStylesFromTool(this.tool);
    const { x, y } = this.cursor;
    switch (this.tool.name) {
      case 'pencil':
        this.ctx.fillRect(x - (this.ctx.lineWidth / 2), y - (this.ctx.lineWidth / 2), this.ctx.lineWidth, this.ctx.lineWidth);
        break;
      case 'brush':
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x, y);
        break;
      case 'eraser':
        // this.ctx.moveTo(x, y);
        // this.ctx.lineTo(x, y);
        this.ctx.clearRect(x - (this.ctx.lineWidth / 2), y - (this.ctx.lineWidth / 2), this.ctx.lineWidth, this.ctx.lineWidth);
        this.ctx.fillRect(x - (this.ctx.lineWidth / 2), y - (this.ctx.lineWidth / 2), this.ctx.lineWidth, this.ctx.lineWidth);
        break;
      case 'fill':
        // alert('This might take a second...');
        bucketFill(this.ctx, this.cursor, this.scale);
        break;
      case 'text':
        this.tempRect = [{ x, y }, { width: 1, height: 1 }];
        this.drawTempRect();
        if (this.tool.variantName === 'background-solid') this.fillTempRect('#ffffff');
        this.drawCursorInTempRect();
        this.drawTextInTempRect();
        break;
      case 'line':
        // this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x - 0.5, y - 0.5);
        this.ctx.stroke();
        // this.ctx.closePath();
        this.ctx.moveTo(x, y);
        break;
      case 'rectangle':
        this.ctx.save();
        this.ctx.setLineDash([]);
        this.ctx.rect(x, y, 1, 1);
        if (this.tool.variantName === 'hollow') {
          this.ctx.stroke();
        } else {
          this.ctx.fill();
        }
        this.ctx.restore();
        break;
      case 'ellipse':
        this.ctx.save();
        this.ctx.setLineDash([]);
        this.ctx.ellipse(x, y, 1, 1, 0, 0, 2 * Math.PI);
        if (this.tool.variantName === 'hollow') {
          this.ctx.stroke();
        } else {
          this.ctx.fill();
        }
        this.ctx.restore();
        break;
    }
  }

  draw(): void {
    if (!this.started) return;

    const { x, y } = this.cursor;
    // this.ctx.beginPath();

    if (!this.drawing) {
      if (this.tool.name === 'text' && this.tempRect) {
        this.restoreSnapshot(this.snapshot);
        this.drawTempRect();
        if (this.tool.variantName === 'background-solid') this.fillTempRect('#ffffff');
        this.drawCursorInTempRect();
        this.drawTextInTempRect();
      }
    } else {
      const pressedStart = this.canvasCoordsFrom(this.mouse.pressedStartPos);
      const { lastCursor } = this;
      switch (this.tool.name) {
        case 'pencil':
          pointsBetween(lastCursor, { x, y }).forEach((point) => {
            // this.ctx.fillRect(point.x, point.y, this.ctx.lineWidth, this.ctx.lineWidth);
            this.ctx.fillRect(point.x - (this.ctx.lineWidth / 2), point.y - (this.ctx.lineWidth / 2), this.ctx.lineWidth, this.ctx.lineWidth);
          });
          break;
        case 'brush':
          this.ctx.lineTo(x, y);
          this.ctx.stroke();
          break;
        case 'eraser':
          // this.ctx.lineTo(x, y);
          // this.ctx.stroke();
          pointsBetween(lastCursor, { x, y }).forEach((point) => {
            // this.ctx.fillRect(point.x, point.y, this.ctx.lineWidth, this.ctx.lineWidth);
            this.ctx.clearRect(point.x - (this.ctx.lineWidth / 2), point.y - (this.ctx.lineWidth / 2), this.ctx.lineWidth, this.ctx.lineWidth);
            this.ctx.fillRect(point.x - (this.ctx.lineWidth / 2), point.y - (this.ctx.lineWidth / 2), this.ctx.lineWidth, this.ctx.lineWidth);
          });
          break;
        case 'fill':
          break;
        case 'text':
          this.restoreSnapshot(this.snapshot);
          this.ctx.beginPath();
          this.tempRect = [pressedStart, { width: x - pressedStart.x, height: y - pressedStart.y }];
          this.drawTempRect();
          if (this.tool.variantName === 'background-solid') this.fillTempRect('#ffffff');
          this.drawCursorInTempRect();
          this.drawTextInTempRect();
          break;
        case 'line':
          this.restoreSnapshot(this.snapshot);
          this.ctx.beginPath();
          this.ctx.moveTo(pressedStart.x, pressedStart.y);
          this.ctx.lineTo(x, y);
          this.ctx.stroke();
          break;
        case 'rectangle':
          this.restoreSnapshot(this.snapshot);
          this.ctx.save();
          this.ctx.beginPath();
          this.ctx.setLineDash([]);
          this.ctx.rect(pressedStart.x, pressedStart.y, x - pressedStart.x, y - pressedStart.y);
          if (this.tool.variantName === 'hollow') {
            this.ctx.stroke();
          } else {
            this.ctx.fill();
          }
          this.ctx.restore();
          break;
        case 'ellipse':
          this.restoreSnapshot(this.snapshot);
          this.ctx.save();
          this.ctx.beginPath();
          this.ctx.setLineDash([]);
          this.ctx.ellipse(pressedStart.x, pressedStart.y, Math.abs(x - pressedStart.x), Math.abs(y - pressedStart.y), 0, 0, 2 * Math.PI);
          if (this.tool.variantName === 'hollow') {
            this.ctx.stroke();
          } else {
            this.ctx.fill();
          }
          this.ctx.restore();
          break;
      }
    }
  }

  stopDrawing(): void {
    if (!this.drawing) return;

    this.drawing = false;
    const pressedStart = this.canvasCoordsFrom(this.mouse.pressedStartPos);
    const pressedEnd = this.canvasCoordsFrom(this.mouse.pressedEndPos);
    this.setStylesFromTool(this.tool);
    const { x, y } = this.cursor;
    this.ctx.moveTo(x, y);
    switch (this.tool.name) {
      case 'pencil': break;
      case 'brush': break;
      // case 'brush': this.ctx.stroke(); break;
      case 'eraser': break;
      // case 'eraser': this.ctx.stroke(); break;
      case 'fill': break;
      case 'text':
        this.restoreSnapshot(this.snapshot);
        this.tempRect = [pressedStart, { width: pressedEnd.x - pressedStart.x, height: pressedEnd.y - pressedStart.y }];
        this.drawTempRect();
        if (this.tool.variantName === 'background-solid') this.fillTempRect('#ffffff');
        this.drawTextInTempRect();
        this.drawCursorInTempRect();
        this.readyForTextHandlers.forEach(handler => handler());
        break;
      case 'line': break;
      case 'rectangle':
        // this.restoreSnapshot(this.snapshot);
        // this.ctx.save();
        // this.ctx.setLineDash([]);
        // if (this.tool.variantName === 'hollow') {
        //   this.ctx.strokeRect(pressedStart.x, pressedStart.y, pressedEnd.x - pressedStart.x, pressedEnd.y - pressedStart.y);
        // } else {
        //   this.ctx.fillRect(pressedStart.x, pressedStart.y, pressedEnd.x - pressedStart.x, pressedEnd.y - pressedStart.y);
        // }
        // this.ctx.restore();
        break;
    }
    // this.ctx.closePath();
  }

  setTool(tool: PainterTool): void {
    this.tool = tool;
    this.setStylesFromTool(tool);
  }

  setColor(color: string): void {
    this.color = color;
    this.setStylesFromColor(color);
  }

  finishTextInput(): void {
    if (!this.tempRect) return;

    this.restoreSnapshot(this.snapshot);
    if (this.tool.variantName === 'background-solid') this.fillTempRect('#ffffff');
    this.drawTextInTempRect();
    this.tempRect = null;
    this.text = '';
  }

  mirrorX(): void {
    const [tmpCanvas, _tmpCtx] = this.duplicateCanvas();
    this.ctx.save();
    this.ctx.clearRect(0, 0, this.size.width, this.size.height);
    this.ctx.translate(this.size.width, 0);
    this.ctx.scale(-1, 1);
    this.ctx.drawImage(tmpCanvas, 0, 0, this.size.width, this.size.height);
    this.ctx.restore();
  }

  mirrorY(): void {
    const [tmpCanvas, _tmpCtx] = this.duplicateCanvas();
    this.ctx.save();
    this.ctx.clearRect(0, 0, this.size.width, this.size.height);
    this.ctx.translate(0, this.size.height);
    this.ctx.scale(1, -1);
    this.ctx.drawImage(tmpCanvas, 0, 0, this.size.width, this.size.height);
    this.ctx.restore();
  }

  rotate(quarterTurns: number): void {
    const [tmpCanvas, _tmpCtx] = this.duplicateCanvas();
    this.ctx.save();
    this.ctx.clearRect(0, 0, this.size.width, this.size.height);
    this.ctx.translate(this.size.width / 2, this.size.height / 2);
    const oneRotation = Math.PI / 2; // 90 deg
    this.ctx.rotate(oneRotation * quarterTurns);
    this.ctx.drawImage(tmpCanvas, -1 * this.size.width / 2, -1 * this.size.height / 2, this.size.width, this.size.height);
    this.ctx.restore();
  }

  // base64Encode(): string {
  //   const tmpCanvas = document.createElement('canvas');
  //   tmpCanvas.width = this.width;
  //   tmpCanvas.height = this.height;
  //   const tmpCtx = tmpCanvas.getContext('2d')!;
  //   tmpCtx.putImageData(this.getSnapshot(), 0, 0, 0, 0, this.width, this.height);
  //   return tmpCanvas.toDataURL('image/jpeg', 1);
  // }

  private duplicateCanvas(): [HTMLCanvasElement, CanvasRenderingContext2D] {
    const tmpCanvas = document.createElement('canvas');
    const tmpCtx = tmpCanvas.getContext('2d')!;
    this.sizeCanvas(tmpCanvas, tmpCtx);
    tmpCtx.drawImage(this.canvas, 0, 0, this.size.width, this.size.height);
    return [tmpCanvas, tmpCtx];
  }

  private canvasCoordsFrom(windowCoords: Coords2d): Coords2d {
    const { top, left } = this.canvas.getBoundingClientRect();
    const elementX = windowCoords.x - left;
    const elementY = windowCoords.y - top;
    return {
      // x: floorToNearestHalf(elementX / this.visualScale), // weird rounding because touch event coords are VERY precise, and I need it to the nearest 0.5px
      // y: floorToNearestHalf(elementY / this.visualScale), // weird rounding because touch event coords are VERY precise, and I need it to the nearest 0.5px
      x: roundToNearestHalf(elementX / this.visualScale), // weird rounding because touch event coords are VERY precise, and I need it to the nearest 0.5px
      y: roundToNearestHalf(elementY / this.visualScale), // weird rounding because touch event coords are VERY precise, and I need it to the nearest 0.5px
    };
  }

  // for delicious crispness
  private sizeCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
    const { width, height } = this.size;
    const { scale, visualScale } = this;
    canvas.width = width * scale;
    canvas.height = height * scale;
    canvas.style.width = `${width * visualScale}px`;
    canvas.style.height = `${height * visualScale}px`;
    ctx.setTransform(1, 0, 0, 1, 0, 0); // reset it to initial scale first
    ctx.scale(scale, scale);
  }

  private getSnapshot(): ImageData {
    return this.ctx.getImageData(0, 0, this.width * this.scale, this.height * this.scale);
  }

  private restoreSnapshot(snapshot: ImageData): void {
    return this.ctx.putImageData(snapshot, 0, 0);
  }

  private setStylesFromColor(color: string): void {
    this.ctx.strokeStyle = color;
    this.ctx.fillStyle = color;
  }

  private setStylesFromTool(tool: PainterTool): void {
    // move all this stuff to method properties on the variants themselves (so
    // they set their own styles, like `tool.variants[0].styleCtx(this.ctx)`)
    switch (tool.name) {
      case 'pencil':
        this.setStylesFromColor(this.color);
        this.ctx.lineWidth = 0.5;
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round';
        this.ctx.miterLimit = 10;
        break;
      case 'brush':
        this.setStylesFromColor(this.color);
        if (tool.variantName.match(/^circle/)) {
          this.ctx.lineCap = 'round';
          this.ctx.lineJoin = 'round';
        } else {
          this.ctx.lineCap = 'butt';
          this.ctx.lineJoin = 'miter';
          this.ctx.miterLimit = 1;
        }
        switch (tool.variantName) {
          case 'circle-1px': this.ctx.lineWidth = 1; break;
          case 'circle-4px': this.ctx.lineWidth = 4; break;
          case 'circle-7px': this.ctx.lineWidth = 7; break;
          case 'square-2px': this.ctx.lineWidth = 2; break;
          case 'square-5px': this.ctx.lineWidth = 5; break;
          case 'square-8px': this.ctx.lineWidth = 8; break;
        }
        break;
      case 'eraser':
        this.setStylesFromColor(this.bgColor);
        this.ctx.lineCap = 'butt';
        this.ctx.lineJoin = 'miter';
        switch (tool.variantName) {
          case '1px': this.ctx.lineWidth = 1; break;
          case '2px': this.ctx.lineWidth = 2; break;
          case '4px': this.ctx.lineWidth = 4; break;
          case '8px': this.ctx.lineWidth = 8; break;
          case '16px': this.ctx.lineWidth = 16; break;
          case '32px': this.ctx.lineWidth = 32; break;
        }
        break;
      case 'fill':
        this.setStylesFromColor(this.color);
        break;
      case 'text':
        this.setStylesFromColor(this.color);
        break;
      case 'line':
        this.setStylesFromColor(this.color);
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round';
        switch (tool.variantName) {
          case '1px': this.ctx.lineWidth = 1; break;
          case '2px': this.ctx.lineWidth = 2; break;
          case '4px': this.ctx.lineWidth = 4; break;
          case '8px': this.ctx.lineWidth = 8; break;
        }
        break;
      case 'rectangle':
        this.setStylesFromColor(this.color);
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round';
        this.ctx.lineWidth = 1;
        break;
      default: break;
    }
  }

  private drawTempRect(): void {
    if (!this.tempRect) return;

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.strokeStyle = '#000000';
    this.ctx.lineWidth = 1;
    this.ctx.setLineDash([5, 2, 2]);
    const [{ x, y }, { width, height }] = this.tempRect;
    this.ctx.strokeRect(x, y, width, height);
    this.ctx.restore();
  }

  private fillTempRect(color: string): void {
    if (!this.tempRect) return;

    this.ctx.save();
    this.ctx.beginPath();
    const [{ x, y }, { width, height }] = this.tempRect;
    this.ctx.fillStyle = color;
    this.ctx.fillRect(x, y, width, height);
    this.ctx.restore();
  }

  private drawCursorInTempRect(): void {
    if (!this.tempRect) return;

    this.ctx.save();
    this.ctx.beginPath();
    const appear = Math.floor(new Date().getTime() / 500) % 2 === 0;
    this.ctx.strokeStyle = appear ? '#000000' : 'transparent';
    this.ctx.lineWidth = 1;
    const [{ x, y }, { height }] = this.tempRect;
    this.ctx.beginPath();
    this.ctx.moveTo(x + this.textWidth + 2, y + 2);
    this.ctx.lineTo(x + this.textWidth + 2, y + height - 2);
    this.ctx.stroke();
    // this.ctx.closePath();
    this.ctx.restore();
  }

  private drawTextInTempRect(): void {
    if (!this.tempRect) return;

    this.ctx.save();
    this.ctx.beginPath();
    const [{ x, y }, { width, height }] = this.tempRect;
    this.ctx.textBaseline = 'top';
    this.ctx.font = `${height}px sans-serif`;
    const theoreticalWidth = this.ctx.measureText(this.text).width;
    this.textWidth = Math.min(theoreticalWidth, width - 4);
    this.ctx.fillText(this.text, x + 2, y + 2, width - 4);
    this.ctx.restore();
  }
}
