import { snakeCase, camelCase } from 'change-case';
import { LastInTuple, MapperFn, OmitNullish } from '@/types/common';

export function isLegitObject(val: any): val is Record<string, any> {
  return Object.prototype.toString.call(val) === '[object Object]';
}

export function caseKeys<V, T extends Record<string, V>>(keyCase: 'snake' | 'camel', obj: T): Record<string, any>;
export function caseKeys<T>(keyCase: 'snake' | 'camel', obj: T): T;
export function caseKeys(keyCase: 'snake' | 'camel', obj: any): any {
  if (Array.isArray(obj)) return obj.map(item => caseKeys(keyCase, item));
  else if (!isLegitObject(obj)) return obj;

  const caseKey = { snake: snakeCase, camel: camelCase }[keyCase];
  return Object.keys(obj).reduce((memo, key) => ({
    ...memo,
    [caseKey(key)]: caseKeys(keyCase, obj[key]),
  }), {});
}

export function camelKeys<V, T extends Record<string, V>>(obj: T): Record<string, any>;
export function camelKeys<T>(obj: T): T;
export function camelKeys(obj: any): any {
  return caseKeys('camel', obj);
}

export function snakeKeys<V, T extends Record<string, V>>(obj: T): Record<string, any>;
export function snakeKeys<T>(obj: T): T;
export function snakeKeys(obj: any): any {
  return caseKeys('snake', obj);
}

export function joinSentences(messages: string[]): string {
  const punctuate = (str: string): string => (str.match(/[,.?!]+$/) ? str : `${str}.`);
  const capitalize = (str: string): string => `${(str[0] || '').toUpperCase()}${str.slice(1)}`;
  return messages.map(err => capitalize(punctuate(err))).join(' ');
}

export function hexToRgba(hexColor: string): [number, number, number, number] {
  const hex = hexColor.replace(/^#/, '').trim().toLowerCase();

  const match = hex.length === 3
    ? hex.match(/^([a-f0-9])([a-f0-9])([a-f0-9])$/i)
    : hex.match(/^([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})$/i);

  if (!match) throw new Error(`Invalid hex color: '${hexColor}'`);

  const [_f, red, green, blue] = match;
  return [parseInt(red, 16), parseInt(green, 16), parseInt(blue, 16), 255];
}

export function fillStyleToRgba(ctx: CanvasRenderingContext2D): [number, number, number, number] {
  const { fillStyle } = ctx;
  if (typeof fillStyle !== 'string') throw new Error('fillStyle is not a color string');

  const rgbaMatch = fillStyle.match(/^rgba?\((\d+), *(\d+), *(\d+)(?:, *(\d+))?/i);
  if (rgbaMatch) {
    const [_f, r, g, b, a] = rgbaMatch;
    return [parseInt(r, 10), parseInt(g, 10), parseInt(b, 10), a ? parseInt(a, 10) : 255];
  }

  return hexToRgba(fillStyle);
}

export function roundToNearestHalf(value: number): number {
  return Math.round(value * 2) / 2;
}

export function floorToNearestHalf(value: number): number {
  return Math.floor(value * 2) / 2;
}

export function isNullish(value: any): value is null | undefined {
  return value === null || typeof value === 'undefined';
}

export const cache = {
  _cache: {} as Record<string, Map<string, any>>,

  store<T extends any[]>(name: string, ...keysAndData: T): LastInTuple<T> {
    const keys = keysAndData.slice(0, keysAndData.length - 1);
    const data = keysAndData[keysAndData.length - 1];
    const cacheKey = keys.map(key => `${key}`).join(':');
    this._cache[name] = this._cache[name] || new Map();
    this._cache[name].set(cacheKey, data);
    return data;
  },

  // eslint-disable-next-line consistent-return
  fetch<T extends any[], L extends LastInTuple<T>>(name: string, ...keysAndFallback: T): L extends () => any ? ReturnType<L> : any {
    this._cache[name] = this._cache[name] || new Map();
    const cache = this._cache[name];
    const fallback = keysAndFallback[keysAndFallback.length - 1];
    const fallbackCallable = typeof fallback === 'function';
    const keys = fallbackCallable ? keysAndFallback.slice(0, keysAndFallback.length - 1) : keysAndFallback;
    const cacheKey = keys.map(key => `${key}`).join(':');
    const existing = cache.get(cacheKey);
    if (existing) return existing;
    if (fallbackCallable) return this.store(name, ...keys, fallback());
    return undefined as L extends () => any ? ReturnType<L> : any;
  },
};

export function uniqueId(): string {
  const timestamp = new Date().getTime().toString(16);
  const rand128 = crypto.getRandomValues(new Uint8Array(16));
  const identifier = Array.from(rand128).map(num => num.toString(16)).join('');
  return `${timestamp}-${identifier}`;
}

export function mapDestroy<T, R>(list: T[], mapper: (item: T, index: number, fullList: T[]) => R): R[] {
  return [...list].map((item, index, fullList) => {
    const value = mapper(item, index, fullList);
    list.splice(index, 1);
    return value;
  });
}

export function replaceAt<T>(list: T[], replacement: T, atIndex: number): T[] {
  return [...list.slice(0, atIndex), replacement, ...list.slice(atIndex + 1)];
}

export function removeAt<T>(list: T[], atIndex: number): T[] {
  return [...list.slice(0, atIndex), ...list.slice(atIndex + 1)];
}

export function bound(value: number, [min, max]: [number, number]): number {
  const [realMin, realMax] = min < max ? [min, max] : [max, min];
  return Math.max(realMin, Math.min(value, realMax));
}

export function omitNullish<T extends Record<string, any>>(obj: T): OmitNullish<T> {
  const keys = Object.keys(obj) as (keyof T)[];
  return keys.reduce((omitted, key) => {
    const value = obj[key];
    return isNullish(value) ? omitted : { ...omitted, [key]: value };
  }, {} as OmitNullish<T>);
}

export function omitBlank<T extends Record<string, any>>(obj: T, { falseIsBlank = true, zeroIsBlank = true }: { falseIsBlank?: boolean; zeroIsBlank?: boolean } = {}): OmitNullish<T> {
  const keys = Object.keys(obj) as (keyof T)[];
  return keys.reduce((omitted, key) => {
    const value = obj[key];

    if (
      isNullish(value)
      || (Array.isArray(value) && !value.length)
      || (isLegitObject(value) && !Object.keys(value).length)
    ) return omitted;

    if (value === false) return falseIsBlank ? omitted : { ...omitted, [key]: value };
    if (value === 0)     return zeroIsBlank  ? omitted : { ...omitted, [key]: value };

    return value ? { ...omitted, [key]: value } : omitted;
  }, {} as OmitNullish<T>);
}

export function toInt(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toNum(value: any): number {
  return parseFloat(`${value}`);
}

export function isVowel(letter: string, sometimesY = false): boolean {
  return `aeiou${sometimesY ? 'y' : ''}`.includes(letter);
}

export function isConsonant(letter: string, sometimesY = false): boolean {
  return !isVowel(letter, sometimesY);
}

export function capitalize(text: string): string {
  return text.replace(/^\w/, first => first.toUpperCase());
}

export function fib(maxLength: number, sequence: number[] = []): number[] {
  const { length } = sequence;

  if (length >= maxLength) return sequence;
  if (length === 0)        return fib(maxLength, [1]);
  if (length === 1)        return fib(maxLength, [1, 1]);

  const previous = sequence[length - 2];
  const current = sequence[length - 1];
  return fib(maxLength, [...sequence, previous + current]);
}

export function sum(numbers: number[]): number {
  return numbers.reduce((memo, num) => memo + num, 0);
}

export function average(numbers: number[]): number {
  return numbers.length ? sum(numbers) / numbers.length : 0;
}

export function allSame<T, K extends keyof T>(list: T[], property?: K): boolean {
  return list.every((item, index) => {
    if (index === 0) return true;
    const prev = list[index - 1];
    return typeof property === 'undefined' ? item === prev : item[property] === prev[property];
  });
}

export function forEachStringSegment(text: string, segmentLength: number, mapper: MapperFn<string, any>): void {
  let index = 0;
  for (let segmentIndex = 0; segmentIndex < text.length; segmentIndex += segmentLength) {
    const segment = text.slice(segmentIndex, segmentIndex + segmentLength);
    mapper(segment, index);
    index += 1;
  }
}

export function mapStringSegments<R>(text: string, segmentLength: number, mapper: MapperFn<string, R>): R[] {
  const results: R[] = [];
  forEachStringSegment(text, segmentLength, (segment, index) => {
    const result = mapper(segment, index);
    results.push(result);
  });
  return results;
}

export function stringSegments(text: string, segmentLength: number): string[] {
  return mapStringSegments(text, segmentLength, segment => segment);
}

export function pick<T extends Record<string, any>, K extends keyof T>(data: T, ...keys: K[]): Pick<T, K> {
  return keys.reduce((memo, key) => ({ ...memo, [key]: data[key] }), {} as Pick<T, K>);
}

export function between(value: number, [min, max]: [number, number], inclusive = true): boolean {
  const [realMin, realMax] = min < max ? [min, max] : [max, min];
  return inclusive
    ? realMin <= value && value <= realMax
    : realMin < value && value < realMax;
}

export function randomBetween(min: number, max: number): number {
  const [realMin, realMax] = min < max ? [min, max] : [max, min];
  return realMin + (Math.random() * (realMax - realMin));
}
