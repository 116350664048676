









































































































import Vue from 'vue';
import Post from '@/models/Post';
import Painter from '@/components/paint/Painter.vue';
import PostItem from '@/components/posts/PostItem.vue';
import FooterTagForm from '@/components/posts/FooterTagForm.vue';
import PigPen from '@/components/stickies/PigPen.vue';
import store from '@/store';
import PostTag from '@/models/PostTag';
import { toastSuccess, toastError } from '@/concerns/helpers';

export default Vue.extend({
  name: 'Home',

  components: {
    Painter,
    PostItem,
    FooterTagForm,
    PigPen,
  },

  data() {
    return {
      newPost: new Post(),
      submittingPost: false,
      painting: false,
    };
  },

  computed: {
    posts(): Post[] {
      return store.state.posts;
    },

    signedIn(): boolean {
      return store.getters.signedIn;
    },
  },

  mounted(): void {
    store.dispatch('fetchPosts');
  },

  methods: {
    toastSuccess,
    toastError,

    addTag(tagName: string): void {
      const postTag = new PostTag({ postId: this.newPost.id, tagName });
      this.newPost.postTags.push(postTag);
    },

    submitPost(): void {
      this.submittingPost = true;
      if (this.painting) {
        this.newPost.image = (this.$refs.painter as any).base64Encode();
      }
      this.newPost.save().then((post) => {
        store.commit('setPost', post);
        this.newPost = new Post();
        this.toastSuccess("post submitted!");
        this.painting = false;
      }).catch((reason) => {
        this.toastError(reason);
      }).finally(() => {
        this.submittingPost = false;
      });
    },
  },
});
