


















































































































































































import axios from 'axios';
import moment from 'moment';
import Vue, { PropType } from 'vue';
import Post from '@/models/Post';
import PostUpvote from '@/models/PostUpvote';
import { modelErrorsFrom } from '@/models/BaseModel';
import store from '@/store';
import { safeRenderMarkdown } from '@/concerns/markdown';
import TagButton from '@/components/common/buttons/TagButton.vue';

export default Vue.extend({
  name: 'PostItem',

  components: {
    TagButton,
  },

  props: {
    post: {
      type: Object as PropType<Post>,
      required: true,
    },
  },

  data() {
    return {
      tagging: false,
      newTagName: "",
      submittingTag: false,
      changingUpvote: false,
      alerts: {
        error: [] as string[],
        info: [] as string[],
        warning: [] as string[],
      },
    };
  },

  computed: {
    humanCreatedAt(): string {
      return moment(this.post.createdAt).fromNow();
    },

    longHumanCreatedAt(): string {
      return moment(this.post.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a");
    },

    currentKarma(): number {
      return this.post.karma;
    },

    signedIn(): boolean {
      return store.getters.signedIn;
    },

    postUpvote(): PostUpvote | null {
      return store.state.user.postUpvotes.find(({ postId }) => postId === this.post.id) || null;
    },

    upvoted(): boolean {
      return !!this.postUpvote;
    },

    postBodyHtml(): string {
      return safeRenderMarkdown(this.post.body);
    },
  },

  methods: {
    toggleTagging(): void {
      this.tagging = !this.tagging;
    },

    // TODO: use model/store
    submitTag(): void {
      this.submittingTag = true;
      const data = { post_tag: { tag_attributes: { name: this.newTagName } } }; // eslint-disable-line @typescript-eslint/camelcase
      axios.post(`/posts/${this.post.id}/post_tags.json`, data)
        .then(() => this.taggedSuccessfullyToast())
        .then(() => this.post.reload())
        .then(() => { this.newTagName = "" })
        .catch((reason) => {
          const errors = modelErrorsFrom(reason, "Something went wrong! Tell Keegan.");
          errors.forEach((error) => {
            if (error.match(/already added/i)) {
              this.taggedDuplicateToast();
            } else {
              this.alerts.error.push(error);
            }
          });
        })
        .finally(() => {
          this.submittingTag = false;
          this.tagging = false;
        });
    },

    taggedSuccessfullyToast(): void {
      this.$bvToast.toast("Tagged!", {
        title: "boop boop",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    taggedDuplicateToast(): void {
      this.$bvToast.toast("Err, uhh, hush up little girl... A lot of cats have that tag.", {
        title: "boop boop",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    upvotePost(): void {
      if (!this.signedIn) {
        this.alerts.error.push("yo you gotta sign in first");
        return;
      }

      this.changingUpvote = true;
      PostUpvote
        .create({ postId: this.post.id }, this.post.id!)
        .then(() => {
          store.dispatch('fetchPost', this.post.id); // to refresh the karma (not blocking)
          return store.dispatch('fetchCurrentUser'); // to refresh the user's upvoted posts (blocking)
        })
        .catch((reason) => {
          const errors = modelErrorsFrom(reason);
          this.alerts.error = [...this.alerts.error, ...errors];
        })
        .finally(() => {
          this.changingUpvote = false;
        });
    },

    removeUpvote(): void {
      if (!this.postUpvote) return;
      if (!this.signedIn) {
        this.alerts.error.push("yo you gotta sign in first");
        return;
      }

      this.changingUpvote = true;
      this.postUpvote
        .destroy()
        .then(() => {
          store.dispatch('fetchPost', this.post.id); // to refresh the karma (not blocking)
          return store.dispatch('fetchCurrentUser'); // to refresh the user's upvoted posts (blocking)
        })
        .catch((reason) => {
          const errors = modelErrorsFrom(reason);
          this.alerts.error = [...this.alerts.error, ...errors];
        })
        .finally(() => {
          this.changingUpvote = false;
        });
    },
  },
});
