









































import _ from 'underscore';
import Vue from 'vue';
import { roundToNearestHalf } from '@/concerns/utilities';
import { Coords2d } from '@/concerns/painter/cartography';

const PIG_WIDTH = 24;
const PIG_HEIGHT = 24;
const FENCE_WIDTH = 100;
const FENCE_HEIGHT = 162;
// const FRAMERATE_MS = 1000;
const FRAMERATE_MS = 800;

type PigState = 'standing' | 'stepping' | 'jumping' | 'landing';

interface Pig {
  id: string;
  state: PigState;
  vector: Coords2d;
  position: Coords2d;
  dragging: boolean;
}

const randomVector = (): Coords2d => ({
  x: Math.round(2 * Math.random()) - 1,
  y: Math.round(2 * Math.random()) - 1,
});

const newPig = (fence: DOMRect): Pig => ({
  id: _.uniqueId('piggy-'),
  vector: randomVector(),
  state: 'standing',
  position: {
    x: Math.random() * (fence.width - PIG_WIDTH),
    y: Math.random() * (fence.height - PIG_HEIGHT),
  },
  dragging: false,
});

/* eslint-disable yoda */
const pigIsContemplatingEscape = (pig: Pig): boolean => {
  // the pig is close to (and moving toward) the left or top fence
  return (0 <= pig.position.x && pig.position.x <= 5 && pig.vector.x > 0)
    || (-5 <= pig.position.x && pig.position.x <= 0 && pig.vector.x < 0)
    || (0 <= pig.position.y && pig.position.y <= 5 && pig.vector.y > 0)
    || (-5 <= pig.position.y && pig.position.y <= 0 && pig.vector.y < 0);
};
/* eslint-enable yoda */

const nextPigState = (pig: Pig): Pig['state'] => {
  const jumpingChance = pigIsContemplatingEscape(pig) ? 0.2 : 0.05;

  const notMoving = pig.vector.x === 0 && pig.vector.y === 0;
  if (notMoving && (pig.state === 'standing' || pig.state === 'stepping')) return 'standing';

  if (Math.random() < jumpingChance) return 'jumping';

  switch (pig.state) {
    case 'standing': return 'stepping';
    case 'stepping': return 'standing';
    case 'jumping': return 'landing';
    case 'landing': return 'standing';
    default: return 'standing';
  }
};

const nextPigVector = (pig: Pig): Pig['vector'] => {
  if (pig.dragging) return pig.vector;

  return Math.random() < 0.2 ? randomVector() : pig.vector;
};

const nextPigPosition = (pig: Pig, fence: DOMRect, athleticism: number): Pig['position'] => {
  if (pig.dragging) return pig.position;

  const midJump = pig.state === 'jumping' || pig.state === 'landing';
  const extraOomf = midJump ? 1.5 : 1;
  const dx = extraOomf * athleticism * pig.vector.x;
  const dy = extraOomf * athleticism * pig.vector.y;
  const oldX = pig.position.x;
  const oldY = pig.position.y;
  const newX = pig.position.x + dx;
  const newY = pig.position.y + dy;
  const leftFence = 0;
  const topFence = 0;
  const rightFence = fence.width - PIG_WIDTH;
  const bottomFence = fence.height - PIG_HEIGHT;
  const leftScreenEdge = -1 * fence.x;
  const topScreenEdge = (-1 * fence.y) + 50;

  const wouldCrossLeftFence = (oldY >= topFence || newY >= topFence)
    && (oldX >= leftFence) !== (newX >= leftFence);

  const wouldCrossTopFence = (oldX >= leftFence || newX >= leftFence)
    && (oldY >= topFence) !== (newY >= topFence);

  const wouldCrossRightFence = (oldX >= rightFence) !== (newX >= rightFence);
  const wouldCrossBottomFence = (oldY >= bottomFence) !== (newY >= bottomFence);
  const wouldCrossLeftScreenEdge = (oldX >= leftScreenEdge) !== (newX >= leftScreenEdge);
  const wouldCrossTopScreenEdge = (oldY >= topScreenEdge) !== (newY >= topScreenEdge);

  const x = (wouldCrossRightFence || wouldCrossLeftScreenEdge)
    ? oldX
    : (wouldCrossLeftFence && !midJump)
      ? 0
      : newX;

  const y = (wouldCrossBottomFence || wouldCrossTopScreenEdge)
    ? oldY
    : (wouldCrossTopFence && !midJump)
      ? 0
      : newY;

  return { x, y };
};

let pigUpdateInterval = 0;
let pigPenMouseMoveHandler: (event: MouseEvent) => void;
let pigPenMouseUpHandler: (event: MouseEvent) => void;
let pigPenTouchMoveHandler: (event: TouchEvent) => void;
let pigPenTouchEndHandler: (event: TouchEvent) => void;

export default Vue.extend({
  data() {
    return {
      pigs: [] as Pig[],
      athleticism: roundToNearestHalf(Math.random() * 5) + 0.5,
    };
  },

  computed: {
    pigWidth(): number { return PIG_WIDTH },
    pigHeight(): number { return PIG_HEIGHT },
    fenceWidth(): number { return FENCE_WIDTH },
    fenceHeight(): number { return FENCE_HEIGHT },
    draggedPig(): Pig | null { return this.pigs.find(({ dragging }) => dragging) || null },
  },

  mounted(): void {
    this.pigs = this.newPigs();
    pigUpdateInterval = window.setInterval(() => this.updatePigs(), FRAMERATE_MS);
    const fence = this.getFence();
    const pigPositionFromCursor = ({ x, y }: Coords2d): Coords2d => ({
      x: x - fence.x - (PIG_WIDTH / 2),
      y: y - fence.y - (PIG_HEIGHT / 2),
    });

    pigPenMouseMoveHandler = (event) => {
      if (!this.draggedPig) return;
      this.draggedPig.position = pigPositionFromCursor(event);
    };

    pigPenMouseUpHandler = () => {
      if (!this.draggedPig) return;
      this.draggedPig.dragging = false;
    };

    pigPenTouchMoveHandler = (event) => {
      if (!this.draggedPig) return;
      const touch = event.touches[0];
      if (!touch) return;
      this.draggedPig.position = pigPositionFromCursor({
        x: touch.clientX,
        y: touch.clientY,
      });
    };

    pigPenTouchEndHandler = () => {
      if (!this.draggedPig) return;
      this.draggedPig.dragging = false;
    };

    document.body.addEventListener('mousemove', pigPenMouseMoveHandler);
    document.body.addEventListener('mouseup', pigPenMouseUpHandler);
    document.body.addEventListener('touchmove', pigPenTouchMoveHandler);
    document.body.addEventListener('touchend', pigPenTouchEndHandler);
  },

  beforeDestroy(): void {
    window.clearInterval(pigUpdateInterval);
    document.body.removeEventListener('mousemove', pigPenMouseMoveHandler);
    document.body.removeEventListener('mouseup', pigPenMouseUpHandler);
    document.body.removeEventListener('touchmove', pigPenTouchMoveHandler);
    document.body.removeEventListener('touchend', pigPenTouchEndHandler);
  },

  methods: {
    getFence(): DOMRect {
      return (this.$refs.fence as HTMLElement).getBoundingClientRect();
    },

    newPigs(): Pig[] {
      const fence = this.getFence();
      return _.range(5).map(() => newPig(fence));
    },

    updatePigs(): void {
      const fence = this.getFence();

      this.pigs.forEach((pig, index) => {
        const newPig = { ...pig };
        newPig.state = nextPigState(newPig);
        newPig.vector = nextPigVector(newPig);

        const rawAthleticism = 3;
        const totalAthleticism = this.athleticism * rawAthleticism;
        newPig.position = nextPigPosition(newPig, fence, totalAthleticism);

        this.pigs.splice(index, 1, newPig);
      });
    },

    onStartDraggingPig(pig: Pig, event: Event): void {
      if (event.cancelable) event.preventDefault();
      pig.dragging = true;
    },
  },
});
