var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pig-pen"},[_c('div',{staticClass:"slider",style:({ width: (_vm.fenceWidth + "px") })},[_c('div',{staticClass:"text-center slider-desc"},[_vm._v("pig athleticism")]),_c('b-form-input',{attrs:{"type":"range","step":"0.5","min":"1","max":"10"},model:{value:(_vm.athleticism),callback:function ($$v) {_vm.athleticism=$$v},expression:"athleticism"}})],1),_c('div',{staticClass:"grass"},[_c('div',{ref:"fence",staticClass:"fence",style:({
        width: (_vm.fenceWidth + "px"),
        height: (_vm.fenceHeight + "px"),
      })},_vm._l((_vm.pigs),function(pig){return _c('div',{key:pig.id,class:['piggy', pig.state, { dragging: pig.dragging, flip: pig.vector.x > 0 }],style:({
          top: ((pig.position.y) + "px"),
          left: ((pig.position.x) + "px"),
          width: (_vm.pigWidth + "px"),
          height: (_vm.pigHeight + "px"),
        }),attrs:{"tabindex":"0"},on:{"mousedown":function($event){return _vm.onStartDraggingPig(pig, $event)},"touchstart":function($event){return _vm.onStartDraggingPig(pig, $event)},"scroll":function($event){$event.preventDefault();}}})}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }