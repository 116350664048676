import BaseModel, { Associations } from '@/models/BaseModel';
import Comment from '@/models/Comment';
import PostTag from '@/models/PostTag';
import { toInt } from '@/concerns/utilities';

export default class Post extends BaseModel {
  public id: null | number;
  public userId: null | number;
  public username: string;
  public title: string;
  public body: string;
  public karma: number;
  public image: string; // base64-encoded image for upload
  public imageUrl: string;
  public editedAt: null | Date;
  public removedAt: null | Date;
  public createdAt: null | Date;
  public updatedAt: null | Date;
  public comments: Comment[];
  public postTags: PostTag[];
  private _commentCount: number;

  constructor(options: Partial<Post> = {}) {
    super(options);
    this.id = toInt(options.id) || null;
    this.userId = toInt(options.userId) || null;
    this.username = options.username || "";
    this.title = options.title || "";
    this.body = options.body || "";
    this.karma = toInt(options.karma) || 0;
    this.image = options.image || "";
    this.imageUrl = options.imageUrl || "";
    this.editedAt = options.editedAt ? new Date(options.editedAt) : null;
    this.removedAt = options.removedAt ? new Date(options.removedAt) : null;
    this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
    this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
    this.comments = options.comments || [];
    this._commentCount = options.commentCount || this.comments.length;
    this.postTags = options.postTags || [];
  }

  static itemName(): string {
    return 'post';
  }

  static hasMany(): Associations {
    return {
      comments: Comment,
      postTags: PostTag,
    };
  }

  // A settable cache for the comment count
  get commentCount(): number {
    this._commentCount = this.comments.length || this._commentCount;
    return this._commentCount;
  }

  set commentCount(count: number) {
    this._commentCount = count;
  }

  toJson(): Record<string, any> {
    return JSON.parse(JSON.stringify({
      ...this,
      // TODO: add option for this in `hasMany()` associations
      postTagsAttributes: this.postTags.map(postTag => postTag.toJson()),
    }));
  }
}
