




















































import Vue from 'vue';
import TagButton from '@/components/common/buttons/TagButton.vue';

export default Vue.extend({
  name: 'FooterTagForm',

  components: {
    TagButton,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    expand: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tagging: this.expand,
      tagName: this.value,
    };
  },

  watch: {
    expand(newVal: boolean, _oldVal: boolean): void {
      if (newVal !== this.tagging) this.tagging = newVal;
    },

    tagging(newVal: boolean, _oldVal: boolean): void {
      if (newVal !== this.expand) this.$emit('update:expand', newVal);
    },

    value(newVal: string, _oldVal: string): void {
      if (newVal !== this.tagName) this.tagName = this.value;
    },

    tagName(newVal: string, _oldVal: string): void {
      this.$emit('input', newVal);
    },
  },

  methods: {
    submitTag(): void {
      this.$emit('submit', this.tagName);
      this.tagName = "";
    },
  },
});
