import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import initLocalforage from '@/init/initLocalforage';
import initBootstrap from '@/init/initBootstrap';
import initAxios from '@/init/initAxios';

initLocalforage();
initBootstrap();
initAxios();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
