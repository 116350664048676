import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    meta: {
      requiresAuth: true,
    },
    // component: '../views/About.vue',
  },
  {
    path: '/posts/:id',
    name: 'Post',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "post" */ '@/views/Post.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sign_in',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "signIn" */ '@/views/SignIn.vue'),
  },
  {
    path: '/paint',
    name: 'Paint',
    component: () => import(/* webpackChunkName: "paint" */ '@/views/Paint.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: () => import(/* webpackChunkName: "rooms" */ '@/views/Rooms.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rooms/at/:x/:y/:z/edit',
    name: 'Room',
    component: () => import(/* webpackChunkName: "roomShow" */ '@/views/RoomShow.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rooms/at/:x/:y/:z/play',
    name: 'RoomPlay',
    component: () => import(/* webpackChunkName: "roomPlay" */ '@/views/RoomPlay.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tunes',
    name: 'Tunes',
    component: () => import(/* webpackChunkName: "Tunes" */ '@/views/Tunes.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/countdowns',
    component: () => import(/* webpackChunkName: "Countdowns" */ '@/views/Countdowns.vue'),
    children: [
      {
        path: '',
        name: 'CountdownsIndex',
        component: () => import(/* webpackChunkName: "CountdownsIndex" */ '@/views/CountdownsIndex.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'new',
        name: 'CountdownNew',
        component: () => import(/* webpackChunkName: "CountdownNew" */ '@/views/CountdownNew.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':id/edit',
        name: 'CountdownEdit',
        component: () => import(/* webpackChunkName: "CountdownEdit" */ '@/views/CountdownEdit.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':id',
        name: 'CountdownShow',
        component: () => import(/* webpackChunkName: "CountdownShow" */ '@/views/CountdownShow.vue'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: '/organisms',
    component: () => import(/* webpackChunkName: "Organisms" */ '@/views/Organisms.vue'),
    children: [
      {
        path: '',
        name: 'OrganismsIndex',
        component: () => import(/* webpackChunkName: "OrganismsIndex" */ '@/views/OrganismsIndex.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'new',
        name: 'OrganismsNew',
        component: () => import(/* webpackChunkName: "OrganismsNew" */ '@/views/OrganismsNew.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':id/edit',
        name: 'OrganismsEdit',
        component: () => import(/* webpackChunkName: "OrganismsEdit" */ '@/views/OrganismsEdit.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':id',
        name: 'OrganismsShow',
        component: () => import(/* webpackChunkName: "OrganismsShow" */ '@/views/OrganismsShow.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/pigs',
    component: () => import(/* webpackChunkName: "Pigs" */ '@/views/Pigs.vue'),
    children: [
      {
        path: '',
        name: 'PigsIndex',
        component: () => import(/* webpackChunkName: "PigsIndex" */ '@/views/PigsIndex.vue'),
        meta: {
          requiresAuth: false,
          hideTopNav: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes,
});

router.beforeEach((to, _from, next) => {
  const routeRequiresLogin = to.matched.some(({ meta }) => meta.requiresAuth);

  const fetchUserIfNotFetched = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (store.state.userFetched) {
        resolve();
      } else {
        store.dispatch('fetchCurrentUser').then(resolve).catch(reject);
      }
    });
  };

  fetchUserIfNotFetched().then(() => {
    if (routeRequiresLogin && !store.getters.signedIn) {
      next({ name: 'SignIn', params: { nextUrl: to.fullPath } });
    } else {
      next();
    }
  });
});

export default router;
