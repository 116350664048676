import { pointExists, pointDataEqual, pointDataFrom, southernmostEqualPoint, pointEqual, northOf, eastOf, westOf, Coords2d, Size } from '@/concerns/painter/cartography';

// A running map of what points should be/are being filled by the bucket fill
// algorithm.
const pointsFilled = new Map<number, Set<number>>();

// Set the point in the `pointsFilled` map, and fill it in on the canvas.
const fillPoint = (ctx: CanvasRenderingContext2D, point: Coords2d): void => {
  const yCoordsAtX = pointsFilled.get(point.x) || new Set();
  pointsFilled.set(point.x, yCoordsAtX.add(point.y));
  // ctx.rect(point.x - 0.5, point.y - 0.5, 0.5, 0.5);
  ctx.rect(point.x - 0.25, point.y - 0.25, 0.5, 0.5);
  // ctx.rect(point.x, point.y, 0.5, 0.5);
};

// Whether or not the point has been/is being filled by the bucket fill
// algorithm.
const pointFilled = (point: Coords2d): boolean => {
  const yCoordsAtX = pointsFilled.get(point.x);
  return !!yCoordsAtX && yCoordsAtX.has(point.y);
};

// Whether or not the point SHOULD be filled by the bucket fill algorithm.
function shouldFillPoint(imageData: Uint8ClampedArray, point: Coords2d, comparisonData: Uint8ClampedArray, canvasSize: Size, scale: number): boolean {
  if (!pointExists(point, canvasSize) || pointFilled(point)) return false;

  const pointData = pointDataFrom(imageData, point, scale);
  return pointDataEqual(comparisonData, pointData);
}

// Fill the contiguous area of equal color around `origin` with the current
// fill style. The `scale` param should be `window.devicePixelRatio` times
// whatever "custom" scale you might be using (usually just 1)
export function bucketFill(ctx: CanvasRenderingContext2D, origin: Coords2d, scale: number): void {
  const size = ctx.canvas.getBoundingClientRect();
  const { width, height } = size;
  const imageDataObj = ctx.getImageData(0, 0, width * scale, height * scale);
  const imageData = imageDataObj.data;
  const comparisonData = pointDataFrom(imageData, origin, scale);

  const southernmostPoint = southernmostEqualPoint(imageData, origin, comparisonData, size, scale);
  let movingEast = true;
  let nextPoint: Coords2d | null = southernmostPoint;
  let lastClearNorthernPoint: Coords2d | null = null;
  const northernStartingVectors: [Coords2d, boolean][] = [];

  const pointFillable = (point: Coords2d): boolean => {
    return shouldFillPoint(imageData, point, comparisonData, size, scale);
  };

  const southernmostFillablePoint = (origin: Coords2d): Coords2d => {
    let furthestSouthPoint = origin;
    for (let i = 0.5; origin.y + i < size.height; i += 0.5) {
      const southwardPoint = { x: origin.x, y: origin.y + i };
      if (!pointFillable(southwardPoint)) break;
      furthestSouthPoint = southwardPoint;
    }
    return furthestSouthPoint;
  };

  const westernmostFillablePoint = (origin: Coords2d): Coords2d => {
    let furthestWestPoint = origin;
    for (let i = 0.5; origin.x - i >= 0; i += 0.5) {
      const westwardPoint = { x: origin.x - i, y: origin.y };
      if (!pointFillable(westwardPoint)) break;
      furthestWestPoint = westwardPoint;
    }
    return furthestWestPoint;
  };

  const easternmostFillablePoint = (origin: Coords2d): Coords2d => {
    let furthestEastPoint = origin;
    for (let i = 0.5; origin.x + i < size.width; i += 0.5) {
      const eastwardPoint = { x: origin.x + i, y: origin.y };
      if (!pointFillable(eastwardPoint)) break;
      furthestEastPoint = eastwardPoint;
    }
    return furthestEastPoint;
  };

  const dropAndBacktrackPoint = (origin: Coords2d): Coords2d => {
    const droppedPoint = southernmostFillablePoint(origin);
    if (pointEqual(droppedPoint, origin)) return droppedPoint;

    const runningStartFor = movingEast ? westernmostFillablePoint : easternmostFillablePoint;
    return runningStartFor(droppedPoint);
  };

  while (nextPoint) {
    fillPoint(ctx, nextPoint);

    const droppedAndBacktrackedPoint = dropAndBacktrackPoint(nextPoint);
    if (!pointEqual(droppedAndBacktrackedPoint, nextPoint)) {
      // Save the current vector, because we're falling and we should probably
      // hold onto it in case we get stuck
      northernStartingVectors.push([nextPoint, movingEast]);
      nextPoint = droppedAndBacktrackedPoint;
      // Get rid of the `lastClearNorthernPoint` (if it isn't already `null`)
      // because it's obsolete now
      lastClearNorthernPoint = null;
      continue;
    }

    // Check the point directly north of this one. If it's clear, update
    // `lastClearNorthernPoint` to be this one so we can keep track of the last
    // time we saw a clear point above us. If it's not clear, then add the last
    // clear one we saw to the `northernStartingVectors` array (along with the
    // reverse of our current direction) because we've encountered a stalactite.
    // This way, we can go back and start again on that one (in the reverse
    // direction of when we spotted it) if we get stuck. Finally, reset the
    // `lastClearNorthernPoint` to `null` so we don't add it twice.
    const northernPoint = northOf(nextPoint);
    if (pointFillable(northernPoint)) {
      // Cool, it's clear!
      lastClearNorthernPoint = northernPoint;
    } else if (lastClearNorthernPoint) {
      // The space CURRENTLY directly to the north is obstructed, but we
      // encountered a clear northern point last time... which means we've just
      // encountered a stalactite. We should remember that clear northern point
      // from last time, and make a note of which direction to move (i.e., away
      // from the edge we just noticed) so we can start from that point again if
      // we trap ourselves in down the line.
      northernStartingVectors.push([lastClearNorthernPoint, !movingEast]);
      lastClearNorthernPoint = null;
    }

    // If there's an unobstructed point forward of the current one, take it and
    // move on.
    const forwardOf = movingEast ? eastOf : westOf;
    const forwardPoint = forwardOf(nextPoint);
    if (pointFillable(forwardPoint)) {
      nextPoint = forwardPoint;
      continue;
    }

    // If the point forward of this one is obstructed, then we'll want to move
    // one row up (since we've already tried to "fall" down) if that point is
    // clear. If it is, then take it, reverse direction, and move on.
    const piledPoint = lastClearNorthernPoint;
    lastClearNorthernPoint = null;
    if (piledPoint) {
      nextPoint = piledPoint;
      movingEast = !movingEast;
      continue;
    }

    // Okay, so we've tried...
    //
    //   1. falling
    //   2. moving forward
    //   3. piling
    //
    // ...and we still haven't found a clear point. Time to go through those
    // points and directions we stored in `northernStartingVectors`.

    // If we have at least one un-filled "arch" from a stalactite we encountered
    // previously (or possibly from falling down over a ledge), we'll start from
    // that point.
    const northernStartingVector = northernStartingVectors.pop();
    if (northernStartingVector) {
      nextPoint = northernStartingVector[0];
      movingEast = northernStartingVector[1];
      continue;
    }

    // If we've gotten here, we've exhausted our options. The contiguously-
    // unobstructed space should now be completely filled.
    nextPoint = null;
  }

  ctx.fill();

  // clear the current collection of `pointsFilled` because we're done filling
  pointsFilled.clear();
}
