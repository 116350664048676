


























































































import Vue, { PropType } from 'vue';
import PainterTool, { getTool, allTools } from '@/models/PainterTool';

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<PainterTool>,
      default: (): PainterTool => getTool('pencil'),
    },

    tools: {
      type: Array as PropType<PainterTool[]>,
      default: (): PainterTool[] => allTools(),
    },
  },

  methods: {
    toolEnabled(toolName: string): boolean {
      const enabledTools = [
        'eraser',
        'pencil',
        'brush',
        'fill',
        'text',
        'line',
        'rectangle',
        'ellipse',
        'mirror-x',
        'mirror-y',
        'rotate-clockwise',
        'rotate-counterclockwise',
      ];

      return enabledTools.includes(toolName);
    },
  },
});
